<template>
  <div class="login-page">

    <form @submit.prevent="onSubmit">
      <a href="/" class="logo-link"><img src="@/assets/img/logo.svg" alt=""></a>
        <input
            type="text"
            class="form-control"
            placeholder="Логин"
            v-model="username"
        />
        <input
            type="password"
            class="form-control"
            placeholder="Пароль"
            v-model="password"
        />
      <button class="btn btn-submit" :disabled="isSubmitting">Войти</button>
    </form>
  </div>
</template>


<script>
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/auth";

export default {
  name: 'SSPLogin',
  components: {

  },
  data() {
    return {
      username: "",
      password: ""
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.auth.isSubmitting,
      isLoggedIn: state => state.auth.isLoggedIn,
    })
  },
  watch: {
    isLoggedIn(){
      if(this.isLoggedIn){
        this.$router.push({name: 'Calculator'})
      }
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch(actionTypes.login, {username: this.username, password: this.password})
    }
  }
}
</script>
