import deliveryApi from '@/api/delivery'

const companies = JSON.parse(process.env.VUE_APP_DELIVERY_COMPANIES)

const state = {
  result: {},
  success: null,
  isLoading: false,
  error: null
}

export const mutationTypes = {
  getCompaniesDataStart: '[delivery] Get CompanyData Start',
  getCompaniesDataSuccess: '[delivery] Get CompanyData Success',
  getCompaniesDataFailure: '[delivery] Get CompanyData Failure',
  getCompaniesDataStop: '[delivery] Get CompanyData Stop',
  setPriorityStart: '[delivery] Set Priority Start',
  setPrioritySuccess: '[delivery] Set Priority Success',
  setPriorityFailure: '[delivery] Set Priority Failure',
  getPriorityStart: '[delivery] Get Priority Start',
  getPrioritySuccess: '[delivery] Get Priority Success',
  getPriorityFailure: '[delivery] Get Priority Failure',
  resetSuccessPriority: '[delivery] ResetPrioritySuccess'
}

export const actionTypes = {
  getCompaniesData: '[delivery] getCompaniesData',
  setPriority: '[delivery] setPriority',
  getPriority: '[delivery] getPriority',
  resetSuccessStatus: '[delivery] resetSuccessStatus'
}

const mutations = {
  [mutationTypes.getCompaniesDataStart](state){
    state.result = {}
    state.isLoading = true
  },
  [mutationTypes.getCompaniesDataSuccess](state, payload){
    state.result[payload.company] = payload.response
  },
  [mutationTypes.getCompaniesDataFailure](state, company){
    delete state.result[company]
  },
  [mutationTypes.getCompaniesDataStop](state){
    state.isLoading = false
  },

  [mutationTypes.setPriorityStart](state){
    state.isLoading = true
    state.error = null
  },
  [mutationTypes.setPrioritySuccess](state, payload){
    state.isLoading = false
    state.success = payload
    state.error = null
  },
  [mutationTypes.setPriorityFailure](state){
    state.isLoading = false
    state.error = true
  },

  [mutationTypes.getPriorityStart](state){
    state.isLoading = true
    state.error = null
  },
  [mutationTypes.getPrioritySuccess](state, payload){
    state.isLoading = false
    state.result = payload.response
    state.error = null
  },
  [mutationTypes.getPriorityFailure](state){
    state.isLoading = false
    state.error = true
  },
  [mutationTypes.resetSuccessPriority](state){
    state.success = null
    state.error = false
  }
}

const actions = {
  [actionTypes.getCompaniesData](context, query) {
    context.commit(mutationTypes.getCompaniesDataStart)
    let prom = []
    companies.forEach(function (element) { prom.push(getCompaniesData(query, element, context)) })
    Promise.all(prom).then(function(){
      context.commit(mutationTypes.getCompaniesDataStop)
    })
  },
  [actionTypes.setPriority](context, params) {
    return new Promise(resolve => {
      context.commit(mutationTypes.setPriorityStart)
      deliveryApi.setPriority(params)
          .then(response => {
            context.commit(mutationTypes.setPrioritySuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.setPriorityFailure)
          })
    })
  },
  [actionTypes.getPriority](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getPriorityStart)
      deliveryApi.getPriority()
          .then(response => {
            context.commit(mutationTypes.getPrioritySuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.getPriorityFailure)
          })
    })
  },
  [actionTypes.resetSuccessStatus](context) {
    context.commit(mutationTypes.resetSuccessPriority)
  },
}

function getCompaniesData(query, company, context) {
  return new Promise(resolve => {
    deliveryApi.getCompanyData({ ...query, company })
        .then(response => {
          if(typeof response.error !== 'undefined') throw response;
          context.commit(mutationTypes.getCompaniesDataSuccess, { company, response })
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getCompaniesDataFailure, company)
        })
  })
}

export default {
  state,
  mutations,
  actions
}
