import {deliveryAxios} from "@/api/axios"

const token = process.env.VUE_APP_DELIVERY_TOKEN;

const getCompanyData = Params => {
    return deliveryAxios.post('/calculate/', {token, ...Params }).then(response => response.data.data)
}

const setPriority = Params => {
    return deliveryAxios.post('/set_priority_tk/', {token, ...Params }).then(response => response.data.data)
}

const getPriority = () => {
    return deliveryAxios.post('/get_priority_tk/', {token}).then(response => response.data.data.response)
}

export default {
    getCompanyData,
    setPriority,
    getPriority
}
