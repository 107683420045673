import {mainAxios} from "@/api/axios"

const config = {
    jsonrpc: "2.0",
    id: 1,
}

const getFormData = async () => {
    return await mainAxios.post('/', {...config, method: "w_get_calc_form_data"}).then(response => response.data.result)
}

const getTireModels = brand => {
    return mainAxios.post('/', {...config, method: "w_get_tire_models", params: {brand}}).then(response => response.data.result.tire_models)
}

const getBuyoutCost = Params => {
    return mainAxios.post('/', {...config, method: "w_get_buyout_cost", params: {...Params}}).then(response => response.data.result)
}

export default {
    getFormData,
    getTireModels,
    getBuyoutCost
}