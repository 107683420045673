export const rangeYears = (start, end) => {
    return [...Array(end - start + 1).keys()].map(el => el + start)
}

export const inJson = str => {
    let r
    try {
        r = JSON.parse(str);
    } catch (e) {
        return str;
    }
    return r;
}

export const pluralDays = number => {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return number + ' дней';
    let last_num = number%10;
    if (last_num === 1) return number + ' день';
    if ([2,3,4].includes(last_num)) return number + ' дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return number + ' дней';
}
