<template>
  <footer>
    <div class="container">
      <p>ООО “Шинсейл”</p>
      <p>Все права защищены, 2021</p>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'SSPFooter',
  props: {
  },
  methods: {
  }
}
</script>
