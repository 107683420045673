<template>
  <div class="calculator-page main-wrapper">
    <SSPHeader/>
    <div class="container">
      <h1>Поиск шины по артикулу</h1>
    </div>
    <main class="container">
      <section class="content">
        <div class="search_code">
          <SSPLoader v-if="isLoading" />
          <div class="fieldset">
            <span>Актикул: </span>
            <div class="action-line">
              <input type="text" v-model="sendData.code">
              <button class="btn btn-submit" @click="searchAndCalc()">Найти шину</button>
            </div>
            <div class="search-result" v-if="this.result.length">
              <div v-for="item in filteredResult" :key="item">
                <div>
                  <strong>{{ item.title }} ({{item.code}})</strong>
                  <p>{{ item.description }}</p>
                </div>
                <div>
                  <a :href="siteHostUrl + 'shini/' + item.slug" class="btn btn-expand" target="_blank">Карточка товара<i class="icon icon-angle-right"></i></a>
                  <a :href="item.link" class="btn btn-submit" target="_blank">Ценообразование<i class="icon icon-angle-right"></i></a>
                </div>

              </div>
            </div>
          </div><!--fieldset-->
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import {actionTypes as generalAction} from "@/store/modules/general";
import {mapState} from "vuex";

export default {
  name: 'SSPCalculator',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader,
  },
  data(){
    return {
      required: {
        code: true,
      },
      sendData: {
        code: '',
      },
      result: [],
      siteHostUrl: process.env.VUE_APP_SITE_URL
    }
  },
  async beforeMount() {
    await this.$store.dispatch(generalAction.getInitData)},
  computed: {
    ...mapState({
      initData: state => state.general.initData,
      tire: state => state.general.tire,
      isLoading: state => state.general.isLoading,
      error: state => state.general.error,
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
    filteredResult(){
      const uniq = new Set(this.result.map(e => JSON.stringify(e)));
      const res = Array.from(uniq).map(e => JSON.parse(e));
      return res.reverse()
    }
  },
  methods: {
    validate(data){
      let count = 0
      Object.keys(this.required).forEach(key => {
        if(!(key in data)) {
          this.required[key] = true
          count++
        }
      })
      if(count === 0) return data
      else return false
    },
    async searchAndCalc() {
      let data = Object.entries(this.sendData).reduce((a,[k,v]) => (v == null ? a : {...a, [k]:v}), {})
      data = this.validate(data)
      if(!data) return false;
      await this.$store.dispatch(generalAction.getTire, {...data})
      let getParams = this.prepareGetData()
      let routeData = this.$router.resolve({path: '/cost/search_and_calc/', query: getParams});
      this.result.push({...this.generateResult(routeData.href)})

    },
    prepareGetData(){
      let params = {};
      params.count = this.tire.counts[0].count
      params.season = this.tire.season
      params.is_studded = this.tire.is_studded
      params.width_front = this.tire.width
      params.width_rear = (this.tire.width_rear) ? this.tire.width_rear : this.tire.width
      params.aspect_ratio_front = this.tire.aspect_ratio
      params.aspect_ratio_rear = (this.tire.aspect_ratio_rear) ? this.tire.aspect_ratio_rear : this.tire.aspect_ratio
      params.diameter = this.tire.diameter
      params.is_runflat = this.tire.is_runflat
      params.is_reinforced = this.tire.is_reinforced
      params.premium = 0
      params.brand = this.initData.tire_brands.find(brand => brand.brand_id === this.tire.brand_id).name
      params.tire_model = this.tire.tire_model_name
      params.wear = this.tire.wear
      params.wear_front = this.tire.wear
      params.wear_rear = this.tire.wear
      params.year = this.tire.year
      params.defect_wear_rate = 1
      params.defect_studded_rate = 1
      params.flat_tire_count = null
      params.cracked_tire_count = null
      params.defect_flaking_rate = 1
      return params
    },
    generateResult(link){
      let resultObj = {};
      resultObj.title = this.initData.tire_brands.find(brand => brand.brand_id === this.tire.brand_id).name +
                        ' ' +  this.tire.tire_model_name
      resultObj.link = link
      resultObj.slug = this.tire.slug
      resultObj.code = this.tire.code
      resultObj.description = this.tire.description

      return resultObj
    }
  }
}
</script>
