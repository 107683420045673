import {generalAxios} from "@/api/axios"

const config = {
    jsonrpc: "2.0",
    id: 1,
}

const getTire = Params => {
    return generalAxios.post('/', {...config, method: "get_tire", params: {...Params}}).then(response => response.data.result)
}

const getInitData = () => {
    return generalAxios.post('/', {...config, method: "get_init_data"}).then(response => response.data.result)
}

export default {
    getTire,
    getInitData
}