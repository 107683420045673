const citiesPriceData = {
    msk: [
        { name: 'Абакан', price: 5000 },
        { name: 'Абинск', price: 3500 },
        { name: 'Адлер', price: 3000 },
        { name: 'Азов', price: 3000 },
        { name: 'Аксай', price: 2500 },
        { name: 'Алапаевск', price: 4000 },
        { name: 'Алатырь', price: 3500 },
        { name: 'Александров', price: 3500 },
        { name: 'Александровское', price: 3500 },
        { name: 'Алексин', price: 3000 },
        { name: 'Алматы', price: 5000 },
        { name: 'Алушта', price: 5000 },
        { name: 'Альметьевск', price: 3500 },
        { name: 'Анапа', price: 3500 },
        { name: 'Ангарск', price: 4500 },
        { name: 'Апатиты', price: 5000 },
        { name: 'Апрелевка', price: 1500 },
        { name: 'Арзамас', price: 2500 },
        { name: 'Армавир', price: 3000 },
        { name: 'Армянск', price: 5000 },
        { name: 'Артем', price: 5000 },
        { name: 'Архангельск', price: 3000 },
        { name: 'Асбест', price: 3500 },
        { name: 'Астрахань', price: 2500 },
        { name: 'Афонино', price: 2000 },
        { name: 'Ачинск', price: 4000 },
        { name: 'Баксан', price: 3500 },
        { name: 'Балаково', price: 3500 },
        { name: 'Балахна', price: 3000 },
        { name: 'Балашиха', price: 1500 },
        { name: 'Балашов', price: 3500 },
        { name: 'Барнаул', price: 3500 },
        { name: 'Батайск', price: 2500 },
        { name: 'Бахчисарай', price: 4500 },
        { name: 'Бежецк', price: 3500 },
        { name: 'Белая Калитва', price: 4000 },
        { name: 'Белгород', price: 2500 },
        { name: 'Белебей', price: 3500 },
        { name: 'Белово', price: 4500 },
        { name: 'Белогорск', price: 8000 },
        { name: 'Белокуриха', price: 5000 },
        { name: 'Белорецк', price: 3500 },
        { name: 'Белореченск', price: 3500 },
        { name: 'Бердск', price: 4000 },
        { name: 'Березники', price: 4500 },
        { name: 'Березовский', price: 3000 },
        { name: 'Березовый', price: 2500 },
        { name: 'Беслан', price: 3000 },
        { name: 'Бийск', price: 4000 },
        { name: 'Биробиджан', price: 6500 },
        { name: 'Благовещенск', price: 6000 },
        { name: 'Благодарный', price: 3000 },
        { name: 'Богородицк', price: 3500 },
        { name: 'Богородск', price: 2500 },
        { name: 'Боковская', price: 2500 },
        { name: 'Бологое', price: 3000 },
        { name: 'Бор', price: 2000 },
        { name: 'Борисовичи', price: 2000 },
        { name: 'Борисоглебск', price: 3500 },
        { name: 'Боровичи', price: 3500 },
        { name: 'Братск', price: 5000 },
        { name: 'Брянск', price: 2000 },
        { name: 'Бугульма', price: 4000 },
        { name: 'Бугуруслан', price: 3500 },
        { name: 'Буденновск', price: 3500 },
        { name: 'Бузулук', price: 4000 },
        { name: 'Буйнакск', price: 4000 },
        { name: 'Бутово', price: 1100 },
        { name: 'Быково', price: 3000 },
        { name: 'Валуйки', price: 3500 },
        { name: 'Ватутинки', price: 1500 },
        { name: 'Великие', price: 3500 },
        { name: 'Великие Луки', price: 3500 },
        { name: 'Великий', price: 3500 },
        { name: 'Великий Новгород', price: 1500 },
        { name: 'Верхняя Пышма', price: 2500 },
        { name: 'Верхняя Салда', price: 3000 },
        { name: 'Веселое', price: 3500 },
        { name: 'Видное', price: 1100 },
        { name: 'Владивосток', price: 6500 },
        { name: 'Владикавказ', price: 2500 },
        { name: 'Владимир', price: 1500 },
        { name: 'ВНИИССОК', price: 1100 },
        { name: 'Воды', price: 4000 },
        { name: 'Волгоград', price: 2500 },
        { name: 'Волгодонск', price: 2500 },
        { name: 'Волжск', price: 3000 },
        { name: 'Волжский', price: 2500 },
        { name: 'Вологда', price: 2000 },
        { name: 'Волхов', price: 3000 },
        { name: 'Вольск', price: 3500 },
        { name: 'Воркута', price: 4000 },
        { name: 'Воронеж', price: 2000 },
        { name: 'Воскресенск', price: 1500 },
        { name: 'Восточный', price: 2500 },
        { name: 'Воткинск', price: 3000 },
        { name: 'Всеволожск', price: 2500 },
        { name: 'Выборг', price: 2000 },
        { name: 'Выкса', price: 3000 },
        { name: 'Высокая Гора', price: 2000 },
        { name: 'Вышний Волочек', price: 3500 },
        { name: 'Вязники', price: 2500 },
        { name: 'Вязьма', price: 3000 },
        { name: 'Гатчина', price: 2500 },
        { name: 'Геленджик', price: 3000 },
        { name: 'Георгиевск', price: 3000 },
        { name: 'Гидростроитель', price: 1100 },
        { name: 'Глазов', price: 4000 },
        { name: 'Голицыно', price: 1500 },
        { name: 'Голубое', price: 1500 },
        { name: 'Горно-Алтайск', price: 5000 },
        { name: 'Городец', price: 2500 },
        { name: 'Горячий Ключ', price: 3500 },
        { name: 'Грибки', price: 1100 },
        { name: 'Грозный', price: 3500 },
        { name: 'Грязи', price: 2500 },
        { name: 'Губкин', price: 3000 },
        { name: 'Губкинский', price: 4500 },
        { name: 'Гусь-Хрустальный', price: 2500 },
        { name: 'Дагомыс', price: 4000 },
        { name: 'Дербент', price: 4000 },
        { name: 'Джанкой', price: 4500 },
        { name: 'Дзержинск', price: 1100 },
        { name: 'Дзержинский', price: 1100 },
        { name: 'Димитровград', price: 3500 },
        { name: 'Дмитров', price: 1500 },
        { name: 'Долгопрудный', price: 1100 },
        { name: 'Домодедово', price: 1500 },
        { name: 'Донецк', price: 3500 },
        { name: 'Дубна', price: 2500 },
        { name: 'Дубовое', price: 2000 },
        { name: 'Душанбе', price: 4000 },
        { name: 'Евпатория', price: 4500 },
        { name: 'Егорлыкская', price: 3000 },
        { name: 'Егорьевск', price: 1500 },
        { name: 'Ейск', price: 3500 },
        { name: 'Екатеринбург', price: 3000 },
        { name: 'Елец', price: 3000 },
        { name: 'Елизово', price: 6000 },
        { name: 'Ессентуки', price: 2500 },
        { name: 'Ефремов', price: 3000 },
        { name: 'Железноводск', price: 3000 },
        { name: 'Железногорск', price: 3000 },
        { name: 'Жигулевск', price: 2500 },
        { name: 'Жилина', price: 2000 },
        { name: 'Жирновск', price: 3000 },
        { name: 'Жуковский', price: 1100 },
        { name: 'Забайкальск', price: 6000 },
        { name: 'Заволжье', price: 2500 },
        { name: 'Заинск', price: 3000 },
        { name: 'Закамск', price: 4000 },
        { name: 'Западный', price: 2500 },
        { name: 'Заречный', price: 3500 },
        { name: 'Звенигород', price: 1500 },
        { name: 'Зеленогорск', price: 2000 },
        { name: 'Зеленоград', price: 1100 },
        { name: 'Зеленодольск', price: 2500 },
        { name: 'Зеленокумск', price: 3500 },
        { name: 'Зерноград', price: 3500 },
        { name: 'Златоуст', price: 4000 },
        { name: 'Знаменск', price: 4500 },
        { name: 'Иваново', price: 2000 },
        { name: 'Ивантеевка', price: 2500 },
        { name: 'Ижевск', price: 2500 },
        { name: 'Избербаш', price: 4000 },
        { name: 'Изобильный', price: 3000 },
        { name: 'Иноземцево', price: 2500 },
        { name: 'Иркутск', price: 4500 },
        { name: 'Истра', price: 3000 },
        { name: 'Ишим', price: 5000 },
        { name: 'Ишимбай', price: 3500 },
        { name: 'Йошкар-Ола', price: 2000 },
        { name: 'Казань', price: 2000 },
        { name: 'Калач-на-Дону', price: 3000 },
        { name: 'Калининград', price: 4000 },
        { name: 'Калитва', price: 2000 },
        { name: 'Калуга', price: 2000 },
        { name: 'Каменоломни', price: 1500 },
        { name: 'Каменск-Уральский', price: 3000 },
        { name: 'Каменск-Шахтинский', price: 3000 },
        { name: 'Камышин', price: 3500 },
        { name: 'Канаш', price: 2500 },
        { name: 'Кандалакша', price: 3000 },
        { name: 'Каневская', price: 3000 },
        { name: 'Канск', price: 6000 },
        { name: 'Карасук', price: 4500 },
        { name: 'Карпинск', price: 5000 },
        { name: 'Каспийск', price: 3500 },
        { name: 'Качканар', price: 5000 },
        { name: 'Кашира', price: 3500 },
        { name: 'Кемерово', price: 4000 },
        { name: 'Керчь', price: 4500 },
        { name: 'Кизляр', price: 4000 },
        { name: 'Кингисепп', price: 3000 },
        { name: 'Кинель', price: 2500 },
        { name: 'Кинешма', price: 3000 },
        { name: 'Кириши', price: 2000 },
        { name: 'Киров', price: 2000 },
        { name: 'Кировград', price: 4000 },
        { name: 'Кирово-Чепецк', price: 3500 },
        { name: 'Киселевск', price: 4500 },
        { name: 'Кисловка', price: 2000 },
        { name: 'Кисловодск', price: 3000 },
        { name: 'Климовск', price: 2000 },
        { name: 'Клин', price: 1500 },
        { name: 'Клинцы', price: 3500 },
        { name: 'Ковров', price: 2500 },
        { name: 'Когалым', price: 5000 },
        { name: 'Кокошкино', price: 1500 },
        { name: 'Коломна', price: 2500 },
        { name: 'Колпино', price: 2000 },
        { name: 'Коммунар', price: 2500 },
        { name: 'Коммунарка', price: 1100 },
        { name: 'Комсомольск-на-Амуре', price: 6000 },
        { name: 'Кондопога', price: 3000 },
        { name: 'Копейск', price: 2500 },
        { name: 'Кореновск', price: 3000 },
        { name: 'Королев', price: 1100 },
        { name: 'Коряжма', price: 3500 },
        { name: 'Костомукша', price: 3500 },
        { name: 'Кострома', price: 2000 },
        { name: 'Котельники', price: 1100 },
        { name: 'Котельниково', price: 3000 },
        { name: 'Котлас', price: 4500 },
        { name: 'Котовск', price: 2500 },
        { name: 'Красноармейск', price: 1500 },
        { name: 'Красногорск', price: 1100 },
        { name: 'Краснодар', price: 2500 },
        { name: 'Красное Село', price: 2000 },
        { name: 'Краснокамск', price: 3000 },
        { name: 'Красноперекопск', price: 4500 },
        { name: 'Краснотурьинск', price: 5000 },
        { name: 'Красноуфимск', price: 3500 },
        { name: 'Красноярск', price: 4000 },
        { name: 'Кронштадт', price: 2500 },
        { name: 'Кропоткин', price: 3000 },
        { name: 'Крымск', price: 3500 },
        { name: 'Кстово', price: 2000 },
        { name: 'Кубинка', price: 2500 },
        { name: 'Кудрово', price: 1100 },
        { name: 'Кузнецк', price: 3500 },
        { name: 'Куйбышев', price: 3500 },
        { name: 'Кунгур', price: 3500 },
        { name: 'Купавна', price: 1100 },
        { name: 'Курган', price: 2500 },
        { name: 'Курск', price: 2000 },
        { name: 'Курчатов', price: 2500 },
        { name: 'Кызыл', price: 5000 },
        { name: 'Кыштым', price: 3500 },
        { name: 'Лабинск', price: 3000 },
        { name: 'Лабытнанги', price: 5000 },
        { name: 'Лазаревское', price: 4000 },
        { name: 'Лангепас', price: 5000 },
        { name: 'Лахта', price: 1100 },
        { name: 'Ленинкент', price: 3000 },
        { name: 'Лениногорск', price: 4000 },
        { name: 'Ленинск-Кузнецкий', price: 5000 },
        { name: 'Лермонтов', price: 2500 },
        { name: 'Лесной', price: 5000 },
        { name: 'Лесной Городок', price: 1100 },
        { name: 'Лесосибирск', price: 5000 },
        { name: 'Ливны', price: 3500 },
        { name: 'Липецк', price: 2000 },
        { name: 'Лобня', price: 1500 },
        { name: 'Ломоносов', price: 1500 },
        { name: 'Лопатино', price: 1500 },
        { name: 'Лосино-Петровский', price: 1100 },
        { name: 'Луга', price: 3000 },
        { name: 'Лысьва', price: 4000 },
        { name: 'Лыткарино', price: 1100 },
        { name: 'Люберцы', price: 1100 },
        { name: 'Людиново', price: 2500 },
        { name: 'Магадан', price: 8000 },
        { name: 'Магнитогорск', price: 3000 },
        { name: 'Майкоп', price: 3500 },
        { name: 'Майский', price: 3500 },
        { name: 'Маркова', price: 4000 },
        { name: 'Махачкала', price: 3000 },
        { name: 'Медведево', price: 2500 },
        { name: 'Междуреченск', price: 4500 },
        { name: 'Металлострой', price: 2000 },
        { name: 'Мехзавод', price: 1500 },
        { name: 'Миасс', price: 3000 },
        { name: 'Миллерово', price: 2500 },
        { name: 'Минеральные', price: 3500 },
        { name: 'Минеральные Воды', price: 2500 },
        { name: 'Минск', price: 2500 },
        { name: 'Минусинск', price: 5000 },
        { name: 'Михайловка', price: 3500 },
        { name: 'Михайловск', price: 3000 },
        { name: 'Мичуринск', price: 3500 },
        { name: 'Можайск', price: 2500 },
        { name: 'Можга', price: 3500 },
        { name: 'Мончегорск', price: 3000 },
        { name: 'Морозовск', price: 3000 },
        { name: 'Моршанск', price: 2500 },
        { name: 'Москва', price: 1100 },
        { name: 'Московский', price: 1100 },
        { name: 'Муравленко', price: 5000 },
        { name: 'Мурино', price: 1500 },
        { name: 'Мурманск', price: 3000 },
        { name: 'Муром', price: 2500 },
        { name: 'Мыски', price: 4500 },
        { name: 'Мытищи', price: 1100 },
        { name: 'Набережные', price: 3000 },
        { name: 'Набережные Челны', price: 2000 },
        { name: 'Нагорное', price: 1100 },
        { name: 'Надым', price: 6000 },
        { name: 'Назарово', price: 5000 },
        { name: 'Назрань', price: 3000 },
        { name: 'Нальчик', price: 3000 },
        { name: 'Наро-Фоминск', price: 2500 },
        { name: 'Нарткала', price: 3500 },
        { name: 'Нарьян-Мар', price: 10000 },
        { name: 'Нахабино', price: 1100 },
        { name: 'Находка', price: 6000 },
        { name: 'Невинномысск', price: 3000 },
        { name: 'Незлобная', price: 2500 },
        { name: 'Нерюнгри', price: 6000 },
        { name: 'Нефтекамск', price: 3500 },
        { name: 'Нефтеюганск', price: 4500 },
        { name: 'Нижневартовск', price: 4000 },
        { name: 'Нижнекамск', price: 3000 },
        { name: 'Нижний Новгород', price: 1500 },
        { name: 'Нижний Тагил', price: 2000 },
        { name: 'Новая Деревня', price: 1500 },
        { name: 'Новая Майна', price: 3500 },
        { name: 'Новая Усмань', price: 1500 },
        { name: 'Новоалександровск', price: 3500 },
        { name: 'Новоалтайск', price: 4500 },
        { name: 'Нововоронеж', price: 2500 },
        { name: 'Новогорелово', price: 1500 },
        { name: 'Новодвинск', price: 3000 },
        { name: 'Новое Девяткино', price: 1500 },
        { name: 'Новоивановское', price: 1500 },
        { name: 'Новокузнецк', price: 4000 },
        { name: 'Новокуйбышевск', price: 2500 },
        { name: 'Новомосковск', price: 3500 },
        { name: 'Новороссийск', price: 2500 },
        { name: 'Новосельцы', price: 1500 },
        { name: 'Новосибирск', price: 3500 },
        { name: 'Новотроицк', price: 4500 },
        { name: 'Новоуральск', price: 4000 },
        { name: 'Новочебоксарск', price: 2000 },
        { name: 'Новочеркасск', price: 2500 },
        { name: 'Новошахтинск', price: 2500 },
        { name: 'Новый', price: 2500 },
        { name: 'Новый Уренгой', price: 3500 },
        { name: 'Ногинск', price: 2000 },
        { name: 'Ноябрьск', price: 3500 },
        { name: 'Нягань', price: 5000 },
        { name: 'Обнинск', price: 2500 },
        { name: 'Одинцово', price: 1100 },
        { name: 'Озерск', price: 3500 },
        { name: 'Октябрьский', price: 2000 },
        { name: 'Омск', price: 3500 },
        { name: 'Орёл', price: 1500 },
        { name: 'Орел-Изумруд', price: 3500 },
        { name: 'Оренбург', price: 2500 },
        { name: 'Орехово-Зуево', price: 2000 },
        { name: 'Орск', price: 3000 },
        { name: 'Осинники', price: 4500 },
        { name: 'Оскол', price: 4000 },
        { name: 'Острогожск', price: 3500 },
        { name: 'Отрадный', price: 3500 },
        { name: 'Павлово', price: 2500 },
        { name: 'Павловская', price: 3000 },
        { name: 'Парголово', price: 1500 },
        { name: 'Пенза', price: 2000 },
        { name: 'Первоуральск', price: 3000 },
        { name: 'Пермь', price: 2500 },
        { name: 'Песчанокопское', price: 3000 },
        { name: 'Петергоф', price: 1500 },
        { name: 'Петрозаводск', price: 2000 },
        { name: 'Петропавловск-Камчатский', price: 8000 },
        { name: 'Печора', price: 5000 },
        { name: 'Подольск', price: 1500 },
        { name: 'Полевской', price: 4000 },
        { name: 'Понтонный', price: 1500 },
        { name: 'Приволжский', price: 1500 },
        { name: 'Приморско-Ахтарск', price: 3500 },
        { name: 'Прокопьевск', price: 4500 },
        { name: 'Пролетарск', price: 3000 },
        { name: 'Прохладный', price: 4000 },
        { name: 'Псков', price: 2000 },
        { name: 'Пугачев', price: 4000 },
        { name: 'Путилково', price: 1100 },
        { name: 'Пушкин', price: 1500 },
        { name: 'Пушкино', price: 1500 },
        { name: 'Пущино', price: 2000 },
        { name: 'Пятигорск', price: 2500 },
        { name: 'Радужный', price: 5000 },
        { name: 'Разумное', price: 2000 },
        { name: 'Раменское', price: 1500 },
        { name: 'Рассказово', price: 2500 },
        { name: 'Ревда', price: 4000 },
        { name: 'Реутов', price: 1100 },
        { name: 'Ржев', price: 2500 },
        { name: 'Россошь', price: 4000 },
        { name: 'Ростов', price: 2000 },
        { name: 'Ростов-на-Дону', price: 2500 },
        { name: 'Ртищево', price: 3500 },
        { name: 'Рубцовск', price: 4000 },
        { name: 'Рыбинск', price: 2000 },
        { name: 'Рязановское', price: 1500 },
        { name: 'Рязань', price: 2000 },
        { name: 'Салават', price: 3500 },
        { name: 'Салехард', price: 6000 },
        { name: 'Сальск', price: 3500 },
        { name: 'Самара', price: 2500 },
        { name: 'Санкт-Петербург', price: 1100 },
        { name: 'Саранск', price: 2000 },
        { name: 'Сарапул', price: 3000 },
        { name: 'Саратов', price: 2000 },
        { name: 'Саров', price: 3000 },
        { name: 'Сатка', price: 3000 },
        { name: 'Сафоново', price: 3000 },
        { name: 'Светлоград', price: 3000 },
        { name: 'Светлый', price: 4000 },
        { name: 'Свободный', price: 6000 },
        { name: 'Севастополь', price: 3500 },
        { name: 'Северодвинск', price: 3000 },
        { name: 'Североморск', price: 3000 },
        { name: 'Североуральск', price: 5000 },
        { name: 'Северск', price: 3000 },
        { name: 'Семикаракорск', price: 3000 },
        { name: 'Сергиев Посад', price: 2000 },
        { name: 'Серов', price: 5000 },
        { name: 'Серпухов', price: 2000 },
        { name: 'Сертолово', price: 1100 },
        { name: 'Сестрорецк', price: 2000 },
        { name: 'Сибай', price: 4000 },
        { name: 'Симферополь', price: 3500 },
        { name: 'Славянск-на-Кубани', price: 3500 },
        { name: 'Сланцы', price: 3000 },
        { name: 'Слободской', price: 2500 },
        { name: 'Смоленск', price: 2000 },
        { name: 'Снежинск', price: 3500 },
        { name: 'Советск', price: 4500 },
        { name: 'Советский', price: 3000 },
        { name: 'Соликамск', price: 4500 },
        { name: 'Солнечногорск', price: 1500 },
        { name: 'Соль-Илецк', price: 3000 },
        { name: 'Сосновоборск', price: 4500 },
        { name: 'Сосновый Бор', price: 2500 },
        { name: 'Сочи', price: 3000 },
        { name: 'Ставрополь', price: 2500 },
        { name: 'Старый Оскол', price: 2000 },
        { name: 'Стерлитамак', price: 2500 },
        { name: 'Стрежевой', price: 5000 },
        { name: 'Стрельна', price: 1500 },
        { name: 'Строитель', price: 2500 },
        { name: 'Ступино', price: 2500 },
        { name: 'Судак', price: 4500 },
        { name: 'Сургут', price: 4000 },
        { name: 'Сухой Лог', price: 3500 },
        { name: 'Сызрань', price: 3000 },
        { name: 'Сыктывкар', price: 2500 },
        { name: 'Тавда', price: 4000 },
        { name: 'Таганрог', price: 2000 },
        { name: 'Тагил', price: 4000 },
        { name: 'Тамбов', price: 2000 },
        { name: 'Тара', price: 3000 },
        { name: 'Тарко-Сале', price: 5500 },
        { name: 'Таштагол', price: 5000 },
        { name: 'Тверь', price: 2000 },
        { name: 'Тельмана', price: 1100 },
        { name: 'Темрюк', price: 3500 },
        { name: 'Тимашевск', price: 3500 },
        { name: 'Тихвин', price: 2500 },
        { name: 'Тихорецк', price: 3500 },
        { name: 'Тобольск', price: 4500 },
        { name: 'Тольятти', price: 2000 },
        { name: 'Томск', price: 4000 },
        { name: 'Торжок', price: 2000 },
        { name: 'Тосно', price: 1500 },
        { name: 'Трехгорный', price: 4000 },
        { name: 'Троицк', price: 2500 },
        { name: 'Туапсе', price: 4000 },
        { name: 'Туймазы', price: 3000 },
        { name: 'Тула', price: 1500 },
        { name: 'Тутаев', price: 2000 },
        { name: 'Тюмень', price: 3000 },
        { name: 'Углич', price: 2500 },
        { name: 'Ужур', price: 5000 },
        { name: 'Улан-Удэ', price: 5000 },
        { name: 'Ульяновск', price: 2000 },
        { name: 'Управленческий', price: 1500 },
        { name: 'Урай', price: 5000 },
        { name: 'Уренгой', price: 5500 },
        { name: 'Урюпинск', price: 3000 },
        { name: 'Усинск', price: 5500 },
        { name: 'Усмань', price: 2500 },
        { name: 'Усолье-Сибирское', price: 5500 },
        { name: 'Уссурийск', price: 6000 },
        { name: 'Усть-Лабинск', price: 3000 },
        { name: 'Уфа', price: 2500 },
        { name: 'Ухта', price: 3500 },
        { name: 'Учалы', price: 4000 },
        { name: 'Феодосия', price: 4500 },
        { name: 'Фролово', price: 3000 },
        { name: 'Фрязино', price: 1500 },
        { name: 'Хабаровск', price: 6000 },
        { name: 'Ханты-Мансийск', price: 4000 },
        { name: 'Хасавюрт', price: 4000 },
        { name: 'Химки', price: 1100 },
        { name: 'Целина', price: 2500 },
        { name: 'Цемдолина', price: 2000 },
        { name: 'Чайковский', price: 3500 },
        { name: 'Чапаевск', price: 3000 },
        { name: 'Чебоксары', price: 2000 },
        { name: 'Челябинск', price: 3000 },
        { name: 'Череповец', price: 2000 },
        { name: 'Черкесск', price: 2500 },
        { name: 'Черноморское', price: 3500 },
        { name: 'Чернушка', price: 3000 },
        { name: 'Чехов', price: 2000 },
        { name: 'Чистополь', price: 3000 },
        { name: 'Чита', price: 5500 },
        { name: 'Чусовой', price: 3500 },
        { name: 'Шадринск', price: 3500 },
        { name: 'Шарыпово', price: 5000 },
        { name: 'Шахты', price: 2500 },
        { name: 'Шебекино', price: 2500 },
        { name: 'Шелехов', price: 5000 },
        { name: 'Шушары', price: 1500 },
        { name: 'Шуя', price: 2500 },
        { name: 'Щелково', price: 1100 },
        { name: 'Щербинка', price: 1500 },
        { name: 'Электросталь', price: 1500 },
        { name: 'Электроугли', price: 1500 },
        { name: 'Элиста', price: 2500 },
        { name: 'Энгельс', price: 2000 },
        { name: 'Югорск', price: 5500 },
        { name: 'Южно-Сахалинск', price: 8000 },
        { name: 'Южноуральск', price: 4000 },
        { name: 'Юрга', price: 4500 },
        { name: 'Юрюзань', price: 3000 },
        { name: 'Яблоновский', price: 2000 },
        { name: 'Якутск', price: 10000 },
        { name: 'Ялта', price: 4500 },
        { name: 'Ялуторовск', price: 3500 },
        { name: 'Янино', price: 1500 },
        { name: 'Ярославль', price: 1500 }
    ],
    spb: [
        { name: 'Абакан', price: 5000 },
        { name: 'Абинск', price: 3500 },
        { name: 'Адлер', price: 3500 },
        { name: 'Азов', price: 3000 },
        { name: 'Аксай', price: 2500 },
        { name: 'Алапаевск', price: 4500 },
        { name: 'Алатырь', price: 3500 },
        { name: 'Александров', price: 4000 },
        { name: 'Александровское', price: 4000 },
        { name: 'Алексин', price: 3000 },
        { name: 'Алматы', price: 4500 },
        { name: 'Алушта', price: 5500 },
        { name: 'Альметьевск', price: 3000 },
        { name: 'Анапа', price: 4000 },
        { name: 'Ангарск', price: 5000 },
        { name: 'Апатиты', price: 3500 },
        { name: 'Апрелевка', price: 1500 },
        { name: 'Арзамас', price: 3000 },
        { name: 'Армавир', price: 3000 },
        { name: 'Армянск', price: 5000 },
        { name: 'Артем', price: 6000 },
        { name: 'Архангельск', price: 3000 },
        { name: 'Асбест', price: 4000 },
        { name: 'Астрахань', price: 3000 },
        { name: 'Афонино', price: 1500 },
        { name: 'Ачинск', price: 5000 },
        { name: 'Баксан', price: 3500 },
        { name: 'Балаково', price: 4000 },
        { name: 'Балахна', price: 3000 },
        { name: 'Балашиха', price: 2000 },
        { name: 'Балашов', price: 4000 },
        { name: 'Барнаул', price: 4000 },
        { name: 'Батайск', price: 2500 },
        { name: 'Бахчисарай', price: 5000 },
        { name: 'Бежецк', price: 3500 },
        { name: 'Белая Калитва', price: 4000 },
        { name: 'Белгород', price: 2500 },
        { name: 'Белебей', price: 4500 },
        { name: 'Белово', price: 5000 },
        { name: 'Белогорск', price: 8000 },
        { name: 'Белокуриха', price: 5000 },
        { name: 'Белорецк', price: 4000 },
        { name: 'Белореченск', price: 4000 },
        { name: 'Бердск', price: 4000 },
        { name: 'Березники', price: 4000 },
        { name: 'Березовский', price: 3000 },
        { name: 'Березовый', price: 2500 },
        { name: 'Беслан', price: 3500 },
        { name: 'Бийск', price: 4000 },
        { name: 'Биробиджан', price: 6000 },
        { name: 'Благовещенск', price: 6000 },
        { name: 'Благодарный', price: 2000 },
        { name: 'Богородицк', price: 4500 },
        { name: 'Богородск', price: 2500 },
        { name: 'Боковская', price: 2500 },
        { name: 'Бологое', price: 3500 },
        { name: 'Бор', price: 2000 },
        { name: 'Борисовичи', price: 1500 },
        { name: 'Борисоглебск', price: 4000 },
        { name: 'Боровичи', price: 3000 },
        { name: 'Братск', price: 5500 },
        { name: 'Брянск', price: 2500 },
        { name: 'Бугульма', price: 4000 },
        { name: 'Бугуруслан', price: 4000 },
        { name: 'Буденновск', price: 4000 },
        { name: 'Бузулук', price: 4500 },
        { name: 'Буйнакск', price: 4500 },
        { name: 'Бутово', price: 1100 },
        { name: 'Быково', price: 3000 },
        { name: 'Валуйки', price: 4000 },
        { name: 'Ватутинки', price: 2000 },
        { name: 'Великие Луки', price: 4000 },
        { name: 'Великий Новгород', price: 2000 },
        { name: 'Верхняя Пышма', price: 2500 },
        { name: 'Верхняя Салда', price: 3500 },
        { name: 'Веселое', price: 4500 },
        { name: 'Видное', price: 1100 },
        { name: 'Владивосток', price: 6000 },
        { name: 'Владикавказ', price: 3000 },
        { name: 'Владимир', price: 2500 },
        { name: 'ВНИИССОК', price: 1500 },
        { name: 'Воды', price: 4000 },
        { name: 'Волгоград', price: 2500 },
        { name: 'Волгодонск', price: 3000 },
        { name: 'Волжск', price: 3500 },
        { name: 'Волжский', price: 2500 },
        { name: 'Вологда', price: 2000 },
        { name: 'Волхов', price: 2500 },
        { name: 'Вольск', price: 4000 },
        { name: 'Воркута', price: 4500 },
        { name: 'Воронеж', price: 2500 },
        { name: 'Воскресенск', price: 2500 },
        { name: 'Восточный', price: 3000 },
        { name: 'Воткинск', price: 3500 },
        { name: 'Всеволожск', price: 2000 },
        { name: 'Выборг', price: 1500 },
        { name: 'Выкса', price: 3500 },
        { name: 'Высокая Гора', price: 2500 },
        { name: 'Вышний', price: 3500 },
        { name: 'Вышний Волочек', price: 3500 },
        { name: 'Вязники', price: 3500 },
        { name: 'Вязьма', price: 3500 },
        { name: 'Гатчина', price: 1500 },
        { name: 'Геленджик', price: 3500 },
        { name: 'Георгиевск', price: 3500 },
        { name: 'Гидростроитель', price: 1100 },
        { name: 'Глазов', price: 4500 },
        { name: 'Голицыно', price: 2000 },
        { name: 'Голубое', price: 1500 },
        { name: 'Горно-Алтайск', price: 5000 },
        { name: 'Городец', price: 3000 },
        { name: 'Горячий Ключ', price: 4000 },
        { name: 'Грибки', price: 2000 },
        { name: 'Грозный', price: 4000 },
        { name: 'Грязи', price: 3000 },
        { name: 'Губкин', price: 3500 },
        { name: 'Губкинский', price: 5000 },
        { name: 'Гусь-Хрустальный', price: 3000 },
        { name: 'Дагомыс', price: 4500 },
        { name: 'Дербент', price: 5000 },
        { name: 'Джанкой', price: 5000 },
        { name: 'Дзержинск', price: 2000 },
        { name: 'Дзержинский', price: 1500 },
        { name: 'Димитровград', price: 4000 },
        { name: 'Дмитров', price: 2000 },
        { name: 'Долгопрудный', price: 1100 },
        { name: 'Домодедово', price: 1500 },
        { name: 'Донецк', price: 3500 },
        { name: 'Дубна', price: 3000 },
        { name: 'Дубовое', price: 3000 },
        { name: 'Душанбе', price: 4000 },
        { name: 'Евпатория', price: 5000 },
        { name: 'Егорлыкская', price: 3000 },
        { name: 'Егорьевск', price: 2000 },
        { name: 'Ейск', price: 4000 },
        { name: 'Екатеринбург', price: 3000 },
        { name: 'Елец', price: 3500 },
        { name: 'Елизово', price: 6000 },
        { name: 'Ессентуки', price: 3000 },
        { name: 'Ефремов', price: 3500 },
        { name: 'Железноводск', price: 3000 },
        { name: 'Железногорск', price: 3500 },
        { name: 'Жигулевск', price: 2500 },
        { name: 'Жилина', price: 2500 },
        { name: 'Жирновск', price: 3500 },
        { name: 'Жуковский', price: 2000 },
        { name: 'Забайкальск', price: 8000 },
        { name: 'Заволжье', price: 2500 },
        { name: 'Заинск', price: 3500 },
        { name: 'Закамск', price: 4000 },
        { name: 'Западный', price: 2000 },
        { name: 'Заречный', price: 3500 },
        { name: 'Звенигород', price: 2000 },
        { name: 'Зеленогорск', price: 2000 },
        { name: 'Зеленоград', price: 2000 },
        { name: 'Зеленодольск', price: 3000 },
        { name: 'Зеленокумск', price: 4000 },
        { name: 'Зерноград', price: 3500 },
        { name: 'Златоуст', price: 4000 },
        { name: 'Знаменск', price: 5000 },
        { name: 'Иваново', price: 2000 },
        { name: 'Ивантеевка', price: 2000 },
        { name: 'Ижевск', price: 2500 },
        { name: 'Избербаш', price: 4500 },
        { name: 'Изобильный', price: 4000 },
        { name: 'Иноземцево', price: 3000 },
        { name: 'Иркутск', price: 5000 },
        { name: 'Истра', price: 2000 },
        { name: 'Ишим', price: 5000 },
        { name: 'Ишимбай', price: 3500 },
        { name: 'Йошкар-Ола', price: 3000 },
        { name: 'Казань', price: 2500 },
        { name: 'Калач-на-Дону', price: 1500 },
        { name: 'Калининград', price: 4000 },
        { name: 'Калитва', price: 2000 },
        { name: 'Калуга', price: 2500 },
        { name: 'Каменоломни', price: 2000 },
        { name: 'Каменск-Уральский', price: 3500 },
        { name: 'Каменск-Шахтинский', price: 3500 },
        { name: 'Камышин', price: 4000 },
        { name: 'Канаш', price: 3000 },
        { name: 'Кандалакша', price: 2500 },
        { name: 'Каневская', price: 4000 },
        { name: 'Канск', price: 6000 },
        { name: 'Карасук', price: 4500 },
        { name: 'Карпинск', price: 5000 },
        { name: 'Каспийск', price: 4500 },
        { name: 'Качканар', price: 5500 },
        { name: 'Кашира', price: 3500 },
        { name: 'Кемерово', price: 4000 },
        { name: 'Керчь', price: 5500 },
        { name: 'Кизляр', price: 5000 },
        { name: 'Кингисепп', price: 2500 },
        { name: 'Кинель', price: 3000 },
        { name: 'Кинешма', price: 3000 },
        { name: 'Кириши', price: 2000 },
        { name: 'Киров', price: 2000 },
        { name: 'Кировград', price: 4000 },
        { name: 'Кирово-Чепецк', price: 4000 },
        { name: 'Киселевск', price: 5000 },
        { name: 'Кисловка', price: 3500 },
        { name: 'Кисловодск', price: 3500 },
        { name: 'Климовск', price: 2000 },
        { name: 'Клин', price: 1500 },
        { name: 'Клинцы', price: 4500 },
        { name: 'Ковров', price: 3000 },
        { name: 'Когалым', price: 5500 },
        { name: 'Кокошкино', price: 1500 },
        { name: 'Коломна', price: 3000 },
        { name: 'Колпино', price: 1100 },
        { name: 'Коммунар', price: 1500 },
        { name: 'Коммунарка', price: 1100 },
        { name: 'Комсомольск-на-Амуре', price: 6000 },
        { name: 'Кондопога', price: 2500 },
        { name: 'Копейск', price: 2000 },
        { name: 'Кореновск', price: 3500 },
        { name: 'Королев', price: 2000 },
        { name: 'Коряжма', price: 3500 },
        { name: 'Костомукша', price: 3500 },
        { name: 'Кострома', price: 2500 },
        { name: 'Котельники', price: 1100 },
        { name: 'Котельниково', price: 3500 },
        { name: 'Котлас', price: 5000 },
        { name: 'Котовск', price: 3000 },
        { name: 'Красноармейск', price: 2000 },
        { name: 'Красногорск', price: 1100 },
        { name: 'Краснодар', price: 3000 },
        { name: 'Красное Село', price: 1500 },
        { name: 'Краснокамск', price: 3500 },
        { name: 'Красноперекопск', price: 5000 },
        { name: 'Краснотурьинск', price: 5000 },
        { name: 'Красноуфимск', price: 3500 },
        { name: 'Красноярск', price: 4500 },
        { name: 'Кронштадт', price: 2000 },
        { name: 'Кропоткин', price: 4000 },
        { name: 'Крымск', price: 4000 },
        { name: 'Кстово', price: 2500 },
        { name: 'Кубинка', price: 2500 },
        { name: 'Кудрово', price: 1100 },
        { name: 'Кузнецк', price: 4000 },
        { name: 'Куйбышев', price: 3500 },
        { name: 'Кунгур', price: 3500 },
        { name: 'Купавна', price: 1100 },
        { name: 'Курган', price: 3000 },
        { name: 'Курск', price: 2500 },
        { name: 'Курчатов', price: 3000 },
        { name: 'Кызыл', price: 6000 },
        { name: 'Кыштым', price: 4000 },
        { name: 'Лабинск', price: 3500 },
        { name: 'Лабытнанги', price: 6000 },
        { name: 'Лазаревское', price: 4500 },
        { name: 'Лангепас', price: 5500 },
        { name: 'Лахта', price: 1100 },
        { name: 'Ленинкент', price: 3500 },
        { name: 'Лениногорск', price: 4000 },
        { name: 'Ленинск-Кузнецкий', price: 5000 },
        { name: 'Лермонтов', price: 3000 },
        { name: 'Лесной', price: 5500 },
        { name: 'Лесной Городок', price: 1500 },
        { name: 'Лесосибирск', price: 5500 },
        { name: 'Ливны', price: 3500 },
        { name: 'Липецк', price: 2500 },
        { name: 'Лобня', price: 2000 },
        { name: 'Ломоносов', price: 1500 },
        { name: 'Лопатино', price: 1500 },
        { name: 'Лосино-Петровский', price: 1100 },
        { name: 'Луга', price: 2000 },
        { name: 'Лысьва', price: 4000 },
        { name: 'Лыткарино', price: 1500 },
        { name: 'Люберцы', price: 1100 },
        { name: 'Людиново', price: 3000 },
        { name: 'Магадан', price: 8000 },
        { name: 'Магнитогорск', price: 3500 },
        { name: 'Майкоп', price: 4000 },
        { name: 'Майский', price: 4000 },
        { name: 'Маркова', price: 4000 },
        { name: 'Махачкала', price: 4000 },
        { name: 'Медведево', price: 3500 },
        { name: 'Междуреченск', price: 5000 },
        { name: 'Металлострой', price: 1100 },
        { name: 'Мехзавод', price: 2000 },
        { name: 'Миасс', price: 3000 },
        { name: 'Миллерово', price: 3500 },
        { name: 'Минеральные', price: 3500 },
        { name: 'Минеральные Воды', price: 3000 },
        { name: 'Минск', price: 3000 },
        { name: 'Минусинск', price: 5500 },
        { name: 'Михайловка', price: 4000 },
        { name: 'Михайловск', price: 3500 },
        { name: 'Мичуринск', price: 4000 },
        { name: 'Можайск', price: 2500 },
        { name: 'Можга', price: 3500 },
        { name: 'Мончегорск', price: 2500 },
        { name: 'Морозовск', price: 3500 },
        { name: 'Моршанск', price: 3500 },
        { name: 'Москва', price: 1500 },
        { name: 'Московский', price: 1100 },
        { name: 'Муравленко', price: 5000 },
        { name: 'Мурино', price: 1100 },
        { name: 'Мурманск', price: 2000 },
        { name: 'Муром', price: 3500 },
        { name: 'Мыски', price: 5000 },
        { name: 'Мытищи', price: 2000 },
        { name: 'Набережные', price: 3500 },
        { name: 'Набережные Челны', price: 2000 },
        { name: 'Нагорное', price: 1100 },
        { name: 'Надым', price: 6000 },
        { name: 'Назарово', price: 5000 },
        { name: 'Назрань', price: 3500 },
        { name: 'Нальчик', price: 3000 },
        { name: 'Наро-Фоминск', price: 2500 },
        { name: 'Нарткала', price: 3500 },
        { name: 'Нарьян-Мар', price: 10000 },
        { name: 'Нахабино', price: 1100 },
        { name: 'Находка', price: 6000 },
        { name: 'Невинномысск', price: 3500 },
        { name: 'Незлобная', price: 3000 },
        { name: 'Нерюнгри', price: 6000 },
        { name: 'Нефтекамск', price: 4000 },
        { name: 'Нефтеюганск', price: 5000 },
        { name: 'Нижневартовск', price: 4500 },
        { name: 'Нижнекамск', price: 3000 },
        { name: 'Нижний Новгород', price: 1500 },
        { name: 'Нижний Тагил', price: 2500 },
        { name: 'Новая Деревня', price: 2000 },
        { name: 'Новая Майна', price: 4000 },
        { name: 'Новая Усмань', price: 1500 },
        { name: 'Новоалександровск', price: 4000 },
        { name: 'Новоалтайск', price: 4500 },
        { name: 'Нововоронеж', price: 3000 },
        { name: 'Новогорелово', price: 1500 },
        { name: 'Новодвинск', price: 3000 },
        { name: 'Новое Девяткино', price: 1100 },
        { name: 'Новоивановское', price: 1500 },
        { name: 'Новокузнецк', price: 4000 },
        { name: 'Новокуйбышевск', price: 3000 },
        { name: 'Новомосковск', price: 4500 },
        { name: 'Новороссийск', price: 3000 },
        { name: 'Новосельцы', price: 2000 },
        { name: 'Новосибирск', price: 4000 },
        { name: 'Новотроицк', price: 4500 },
        { name: 'Новоуральск', price: 4000 },
        { name: 'Новочебоксарск', price: 2500 },
        { name: 'Новочеркасск', price: 3000 },
        { name: 'Новошахтинск', price: 3000 },
        { name: 'Новый', price: 3000 },
        { name: 'Новый Уренгой', price: 4500 },
        { name: 'Ногинск', price: 2000 },
        { name: 'Норильск', price: 6000 },
        { name: 'Ноябрьск', price: 4000 },
        { name: 'Нягань', price: 2500 },
        { name: 'Обнинск', price: 3000 },
        { name: 'Одинцово', price: 1100 },
        { name: 'Озерск', price: 4000 },
        { name: 'Октябрьский', price: 2500 },
        { name: 'Омск', price: 4000 },
        { name: 'Орёл', price: 1500 },
        { name: 'Орел-Изумруд', price: 4000 },
        { name: 'Оренбург', price: 3000 },
        { name: 'Орехово-Зуево', price: 2000 },
        { name: 'Орск', price: 3500 },
        { name: 'Осинники', price: 4500 },
        { name: 'Оскол', price: 4000 },
        { name: 'Острогожск', price: 3500 },
        { name: 'Отрадный', price: 3500 },
        { name: 'Павлово', price: 3000 },
        { name: 'Павловская', price: 4000 },
        { name: 'Парголово', price: 1500 },
        { name: 'Пенза', price: 2500 },
        { name: 'Первоуральск', price: 3000 },
        { name: 'Пермь', price: 3000 },
        { name: 'Песчанокопское', price: 3000 },
        { name: 'Петергоф', price: 1500 },
        { name: 'Петрозаводск', price: 1500 },
        { name: 'Петропавловск-Камчатский', price: 6000 },
        { name: 'Печора', price: 5500 },
        { name: 'Подольск', price: 1500 },
        { name: 'Полевской', price: 4000 },
        { name: 'Понтонный', price: 1500 },
        { name: 'Приволжский', price: 2000 },
        { name: 'Приморско-Ахтарск', price: 4000 },
        { name: 'Прокопьевск', price: 5000 },
        { name: 'Пролетарск', price: 3000 },
        { name: 'Прохладный', price: 4500 },
        { name: 'Псков', price: 2000 },
        { name: 'Пугачев', price: 4500 },
        { name: 'Путилково', price: 1100 },
        { name: 'Пушкин', price: 1500 },
        { name: 'Пушкино', price: 1500 },
        { name: 'Пущино', price: 2500 },
        { name: 'Пятигорск', price: 3000 },
        { name: 'Радужный', price: 5500 },
        { name: 'Разумное', price: 2500 },
        { name: 'Раменское', price: 1500 },
        { name: 'Рассказово', price: 3000 },
        { name: 'Ревда', price: 4000 },
        { name: 'Реутов', price: 1100 },
        { name: 'Ржев', price: 2000 },
        { name: 'Россошь', price: 4500 },
        { name: 'Ростов', price: 2500 },
        { name: 'Ростов-на-Дону', price: 2500 },
        { name: 'Ртищево', price: 4000 },
        { name: 'Рубцовск', price: 4000 },
        { name: 'Рыбинск', price: 2500 },
        { name: 'Рязановское', price: 1500 },
        { name: 'Рязань', price: 2500 },
        { name: 'Салават', price: 3500 },
        { name: 'Салехард', price: 6000 },
        { name: 'Сальск', price: 4000 },
        { name: 'Самара', price: 2500 },
        { name: 'Санкт-Петербург', price: 1100 },
        { name: 'Саранск', price: 2500 },
        { name: 'Сарапул', price: 3500 },
        { name: 'Саратов', price: 2500 },
        { name: 'Саров', price: 3500 },
        { name: 'Сатка', price: 3500 },
        { name: 'Сафоново', price: 3000 },
        { name: 'Светлоград', price: 4000 },
        { name: 'Светлый', price: 4000 },
        { name: 'Свободный', price: 6000 },
        { name: 'Севастополь', price: 4500 },
        { name: 'Северодвинск', price: 2500 },
        { name: 'Североморск', price: 2500 },
        { name: 'Североуральск', price: 5000 },
        { name: 'Северск', price: 3500 },
        { name: 'Семикаракорск', price: 4000 },
        { name: 'Сергиев Посад', price: 2000 },
        { name: 'Серов', price: 5000 },
        { name: 'Серпухов', price: 2000 },
        { name: 'Сертолово', price: 1100 },
        { name: 'Сестрорецк', price: 2000 },
        { name: 'Сибай', price: 4500 },
        { name: 'Симферополь', price: 4000 },
        { name: 'Славянск-на-Кубани', price: 3500 },
        { name: 'Сланцы', price: 2500 },
        { name: 'Слободской', price: 3000 },
        { name: 'Смоленск', price: 2500 },
        { name: 'Снежинск', price: 4000 },
        { name: 'Советск', price: 4500 },
        { name: 'Советский', price: 2000 },
        { name: 'Соликамск', price: 4500 },
        { name: 'Солнечногорск', price: 2500 },
        { name: 'Соль-Илецк', price: 4000 },
        { name: 'Сосновоборск', price: 5000 },
        { name: 'Сосновый Бор', price: 2500 },
        { name: 'Сочи', price: 3500 },
        { name: 'Ставрополь', price: 3000 },
        { name: 'Старый Оскол', price: 2000 },
        { name: 'Стерлитамак', price: 3000 },
        { name: 'Стрежевой', price: 5500 },
        { name: 'Стрельна', price: 1500 },
        { name: 'Строитель', price: 3000 },
        { name: 'Ступино', price: 2500 },
        { name: 'Судак', price: 5000 },
        { name: 'Сургут', price: 4500 },
        { name: 'Сухой Лог', price: 3500 },
        { name: 'Сызрань', price: 4000 },
        { name: 'Сыктывкар', price: 3000 },
        { name: 'Тавда', price: 4500 },
        { name: 'Таганрог', price: 2500 },
        { name: 'Тагил', price: 4000 },
        { name: 'Тамбов', price: 2500 },
        { name: 'Тара', price: 4000 },
        { name: 'Тарко-Сале', price: 6000 },
        { name: 'Таштагол', price: 5000 },
        { name: 'Тверь', price: 2500 },
        { name: 'Тельмана', price: 1100 },
        { name: 'Темрюк', price: 4000 },
        { name: 'Тимашевск', price: 3500 },
        { name: 'Тихвин', price: 2500 },
        { name: 'Тихорецк', price: 4000 },
        { name: 'Тобольск', price: 5000 },
        { name: 'Тольятти', price: 2500 },
        { name: 'Томск', price: 4000 },
        { name: 'Торжок', price: 3000 },
        { name: 'Тосно', price: 1500 },
        { name: 'Трехгорный', price: 4500 },
        { name: 'Троицк', price: 3000 },
        { name: 'Туапсе', price: 4500 },
        { name: 'Туймазы', price: 3000 },
        { name: 'Тула', price: 2500 },
        { name: 'Тутаев', price: 2500 },
        { name: 'Тюмень', price: 3000 },
        { name: 'Углич', price: 3000 },
        { name: 'Ужур', price: 5000 },
        { name: 'Улан-Удэ', price: 5500 },
        { name: 'Ульяновск', price: 3000 },
        { name: 'Управленческий', price: 2000 },
        { name: 'Урай', price: 5000 },
        { name: 'Уренгой', price: 6000 },
        { name: 'Урюпинск', price: 3000 },
        { name: 'Усинск', price: 6000 },
        { name: 'Усмань', price: 3500 },
        { name: 'Усолье-Сибирское', price: 6000 },
        { name: 'Уссурийск', price: 6000 },
        { name: 'Усть-Лабинск', price: 3500 },
        { name: 'Уфа', price: 3000 },
        { name: 'Ухта', price: 4000 },
        { name: 'Учалы', price: 4500 },
        { name: 'Феодосия', price: 5500 },
        { name: 'Фролово', price: 3500 },
        { name: 'Фрязино', price: 1500 },
        { name: 'Хабаровск', price: 6000 },
        { name: 'Ханты-Мансийск', price: 4000 },
        { name: 'Хасавюрт', price: 5000 },
        { name: 'Химки', price: 1500 },
        { name: 'Целина', price: 2500 },
        { name: 'Цемдолина', price: 2500 },
        { name: 'Чайковский', price: 3500 },
        { name: 'Чапаевск', price: 3500 },
        { name: 'Чебоксары', price: 2500 },
        { name: 'Челябинск', price: 3000 },
        { name: 'Череповец', price: 2000 },
        { name: 'Черкесск', price: 3000 },
        { name: 'Черноморское', price: 3500 },
        { name: 'Чернушка', price: 3500 },
        { name: 'Чехов', price: 2000 },
        { name: 'Чистополь', price: 3500 },
        { name: 'Чита', price: 6000 },
        { name: 'Чусовой', price: 4000 },
        { name: 'Шадринск', price: 4000 },
        { name: 'Шарыпово', price: 5000 },
        { name: 'Шахты', price: 2500 },
        { name: 'Шебекино', price: 3000 },
        { name: 'Шелехов', price: 5500 },
        { name: 'Шушары', price: 1500 },
        { name: 'Шуя', price: 2500 },
        { name: 'Щелково', price: 1100 },
        { name: 'Щербинка', price: 1500 },
        { name: 'Электросталь', price: 2500 },
        { name: 'Электроугли', price: 2000 },
        { name: 'Элиста', price: 3000 },
        { name: 'Энгельс', price: 3000 },
        { name: 'Югорск', price: 6000 },
        { name: 'Южно-Сахалинск', price: 8000 },
        { name: 'Южноуральск', price: 4000 },
        { name: 'Юрга', price: 5000 },
        { name: 'Юрюзань', price: 3500 },
        { name: 'Яблоновский', price: 2000 },
        { name: 'Якутск', price: 10000 },
        { name: 'Ялта', price: 5000 },
        { name: 'Ялуторовск', price: 4000 },
        { name: 'Янино', price: 1500 },
        { name: 'Ярославль', price: 2000 }
    ]
}
export default citiesPriceData
