const citiesTimeData = {
    msk: [
        { name: 'Новосибирск', time: 5 },
        { name: 'Екатеринбург', time: 4 },
        { name: 'Казань', time: 3 },
        { name: 'Челябинск', time: 4 },
        { name: 'Самара', time: 3 },
        { name: 'Уфа', time: 4 },
        { name: 'Ростов-на-Дону', time: 3 },
        { name: 'Омск', time: 5 },
        { name: 'Волгоград', time: 3 },
    ],
    spb: [
        { name: 'Новосибирск', time: 7 },
        { name: 'Екатеринбург', time: 5 },
        { name: 'Казань', time: 3 },
        { name: 'Нижний Новгород', time: 3 },
        { name: 'Челябинск', time: 5 },
        { name: 'Самара', time: 5 },
        { name: 'Уфа', time: 5 },
        { name: 'Ростов-на-Дону', time: 4 },
        { name: 'Омск', time: 7 },
        { name: 'Волгоград', time: 4 },
    ]
}
export default citiesTimeData
