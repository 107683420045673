import { createStore } from 'vuex'

import auth from "@/store/modules/auth";
import calculator from "@/store/modules/calculator";
import general from "@/store/modules/general";
import dadata from "@/store/modules/dadata";
import delivery from "@/store/modules/delivery";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    calculator,
    general,
    dadata,
    delivery
  }
})
