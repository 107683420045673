import citiesPriceData from "@/helpers/data/citiesPrice";
import citiesTimeData from "@/helpers/data/citiesTime";

let citiesToInfo = []

export function calculatorBuilder(props)
{
  var colorScheme = escapeHtml(props.colorScheme);
  var buttonTextColor = escapeHtml(props.buttonTextColor);
  var domain = 'https://transportnye-kompanii.com';
  let ourPrice = 2500;

  function plural(number, titles) {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  }

  function escapeHtml(unsafe) {
    return String(unsafe)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
  }

  function loadCss(path, id) {
    if (!document.getElementById(id)) {
      var head = document.getElementsByTagName("head")[0];
      var link = document.createElement("link");
      link.id = id;
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = path;
      head.appendChild(link);
    }
  }

  function loadJs(path, id, callback) {
    if (!document.getElementById(id)) {
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.id = id;
      script.onload = function () {
        callback();
      };
      script.src = path;
      head.appendChild(script);
    } else callback();
  }

  window.trankompOnlyNumbers = function (obj, min, max, postfix) {
    obj.value = obj.value.replace(/(\.|,)+/g, '.');
    obj.value = obj.value.replace(/ .*|[^0-9\.,]/g, '');
    var val = Number(obj.value);

    // Validate minimum value only if focus is lost
    obj.onblur = function () {
      this.onblur = null;

      if (min && val < min) obj.value = min + (postfix ? " " + postfix : '');
    };

    if (!val) return;

    var posCursor = obj.selectionStart;

    if (max && val > max) obj.value = max;

    if (postfix) {
      posCursor = posCursor > obj.value.length ? obj.value.length : posCursor;
      obj.value = obj.value + " " + postfix;
      obj.setSelectionRange(posCursor, posCursor);
    }
  };

  var textInput = function (data) {
    return (
        '<input type="text" ' +
        (data.required ? 'required' : '') + '\
        name="trankomp' + data.inputName + '"\
        value="' + (data.value ? data.value : '') +
        '" placeholder="' + (data.placeholder ? data.placeholder : '') +
        '" class="trankomp-input" style="width:100px;"\
        ' + (data.onInput ? 'oninput="' + data.onInput + '"' : '') + '\
        ' + (data.onInput ? 'onfocus="' + data.onInput + '"' : '') + '\
      >'
    );
  };

  var checkboxInput = function (opts) {
    return (
        "<input " + (opts.checked ? "checked" : "") + '\
        class="trankompCheckbox"\
        id="trankomp' + opts.inputName + opts.value + '"\
        type="checkbox"\
        value="' + opts.value + '"\
        name="trankomp' + opts.inputName + '"\
        onchange="' + (opts.onChange ? opts.onChange : '') + '"\
      >\
      <label for="trankomp' + opts.inputName + opts.value + '" style="display: flex; align-items: center;">\
        <div style="display: inline-flex;align-items: center;">\
          ' + (opts.companyName ? '<img src="' + domain + '/img/logos-mini/' + opts.companyName + '.png" style="width: 25px; margin-right: 3px" />' : "") + '\
          <span style="vertical-align: middle;">' + opts.label + "</span>\
        </div>\
      </label>"
    );
  };

  var fields = {
    cargoFields: function (props) {
      var cargoPickup = "";
      var cargoDelivery = "";

      if (props.cargoPickup === true)
        cargoPickup = checkboxInput({inputName: "CargoPickup", value: 1, label: "Забрать груз от дверей"});

      if (props.cargoDelivery === true) {
        cargoDelivery = checkboxInput({
          inputName: "CargoDelivery",
          value: 1,
          label: "Доставить груз до дверей"
        });
      }

      return cargoDelivery == "" && cargoPickup == ""
          ? ""
          : '<div class="trankomp-splitter-container">\
              <div class="trankomp-splitter" style="' +
          (cargoPickup == "" ? "margin: 0;" : "") +
          '">' +
          cargoPickup +
          '</div>\
            <div class="trankomp-splitter" style="' +
          (cargoDelivery == "" ? "display: none;" : "") +
          '">' +
          cargoDelivery +
          "</div>\
          </div>";
    },

    volumeField: function () {
      window.trankompFieldSwitcher = function (fieldType) {
        var activeChunk = document.querySelector(".trankomp-chunk-active");
        if (activeChunk) {
          activeChunk.classList.remove("trankomp-chunk-active");
        }

        document
            .querySelector(".trankomp-chunk" + (fieldType === "volume" ? 1 : 2))
            .classList.add("trankomp-chunk-active");

        var field = fieldType === "volume"
            ?
            textInput({
              placeholder: "0.001 м3",
              inputName: "Volume",
              required: true,
              onInput: 'trankompOnlyNumbers(this, 0.001, 80, \'м3\')',
              value: "0.6 м3",
            })
            :
            textInput({
              placeholder: "10 см",
              inputName: "Length",
              required: true,
              onInput: 'trankompOnlyNumbers(this, 10, 300, \'см\')'
            }) +
            " X " +
            textInput({
              placeholder: "10 см",
              inputName: "Width",
              required: true,
              onInput: 'trankompOnlyNumbers(this, 10, 300, \'см\')'
            }) +
            " X " +
            textInput({
              placeholder: "10 см",
              inputName: "Height",
              required: true,
              onInput: 'trankompOnlyNumbers(this, 10, 300, \'см\')'
            });

        document.querySelector("#trankompVolume").innerHTML = field;
      };

      return '<div class="trankomp-field-title">\
          <div class="trankomp-switcher">\
              <div class="trankomp-chunk1" onclick="window.trankompFieldSwitcher(\'volume\')">Объем, м<sup>3</sup></div><div class="trankomp-chunk2" onclick="trankompFieldSwitcher()">Габариты, см (Д/Ш/В)</div>\
          </div>\
          <div id="trankompVolume"></div>\
      </div>';
    },

    insurance: function (props) {
      var checkboxes = "";

      window.trankompToggleDeclaredCostField = function (obj) {
        document.querySelector('#trankopmDeclaredCostId').style.display = obj.checked ? 'inline-block' : 'none';
      }

      if (props.insurance === true) {
        checkboxes += "<div style='display: flex;align-items: center;'>" + checkboxInput({
              inputName: "Insurance",
              value: 1,
              label: "Страхование",
              checked: false,
              onChange: 'trankompToggleDeclaredCostField(this)'
            }) + "\
          <span id='trankopmDeclaredCostId' style='display: none;'>\
            &nbsp;/ Укажите стоимость груза для расчета страховки (руб.) " +
            textInput({
              inputName: "DeclaredCost",
              onInput: 'trankompOnlyNumbers(this, 100, 500000)'
            }) +
            "\
            </span>\
          </div>";
      }

      if (props.rigidPackaging === true)
        checkboxes += "<div>" + checkboxInput({
          inputName: "RigidPackaging",
          value: 1,
          label: "Жесткая упаковка (обрешетка)"
        }) + "\
        </div>";

      return checkboxes !== ""
          ? '<div class="trankomp-field">' + checkboxes + "</div>"
          : "";
    },

    companies: function (props) {
      var checkboxes = "";

      for (var i = 0; i < props.companies.length; i++) {
        var company = props.companies[i];

        checkboxes +=
            '<div style="display: inline-block; margin-right: 10px;">\
            ' +
            checkboxInput({
              companyName: escapeHtml(company[0]),
              inputName: 'Companies[]',
              value: escapeHtml(company[0]),
              label: escapeHtml(company[1]),
              checked: true
            }) +
            "\
          </div>";
      }

      return (
          '<div class="trankomp-field">\
            <div class="trankomp-field-title">Транспортные компании:</div>\
            ' +
          checkboxes +
          "\
        </div>"
      );
    },
  };

  var markup =
      '<form method="POST" id="trankomp">\
      ' + (props.title.trim() == ''
      ? ''
      : '<div class="trankomp-title" style="border-color: ' + colorScheme + ';">' + escapeHtml(props.title) + '</div>') +
      '\
      <div class="trankomp-field trankomp-splitter-container">\
          <div class="trankomp-splitter">\
              <div class="trankomp-field-title">Откуда</div>\
              <div id="trankomp-city-from-input"></div>\
          </div>\
          <div class="trankomp-splitter">\
              <div class="trankomp-field-title">Куда</div>\
              <div id="trankomp-city-to-input"></div>\
          </div>\
      </div>\
      ' +
      fields.cargoFields(props) + '\
    <div class="trankomp-splitter-container">\
        <div class="trankomp-splitter">\
            <div class="trankomp-field-title trankomp-switcher" style="padding: 6px 0;">Вес груза, кг</div>\
            ' +
      textInput({
        placeholder: "1 кг",
        inputName: "Weight",
        required: true,
        value: "60 кг",
        onInput: 'trankompOnlyNumbers(this, 1, 20000, \'кг\')'
      }) +
      '\
    </div>\
    <div class="trankomp-splitter">\
        ' +
      fields.volumeField() + "\
        </div>\
    </div>\
    \
    " + fields.insurance(props) + "\
    " + fields.companies(props) + '\
    \
    <div class="trankomp-field">\
        <button type="submit" class="trankomp-btn" style="background: ' + colorScheme + ';color: ' + buttonTextColor + ';">Рассчитать</button>\
    </div>\
    <div id="trankompErrors" style="display: none;padding-top: 12px;color: tomato;"></div>\
    \
    <div class="trankomp-footer" style="color: #bdbdbd;font-size: 12px;border-color: ' + colorScheme + ';"">\
        <div id="trankompResult" style="display: none;"></div>\
        \
        <div class="trankomp-splitter-container no_visible">\
          <div class="trankomp-splitter">\
              Стоимость рассчитывается ориентировочно\
          </div>\
          <div class="trankomp-splitter" style="text-align: right;">\
              <a href="http://transportnye-kompanii.com/" target="_blank" style="color: #bdbdbd;">transportnye-kompanii.com</a>\
          </div>\
        </div>\
    </div>\
  </form>';

  document.querySelector("#transportnye-kompanii").innerHTML = markup;

  window.trankompFieldSwitcher("volume");

  document.querySelector('#trankomp').onsubmit = function (e) {
    e.preventDefault();

    var errorContainer = document.querySelector('#trankompErrors');
    errorContainer.style.display = 'none';

    var checkedBoxes = document.querySelectorAll('input[name="trankompCompanies[]"]:checked');
    if (checkedBoxes.length == 0) {
      errorContainer.innerHTML = 'Выберите хотя бы одну транспортную компанию';
      errorContainer.style.display = 'block';
      return;
    }

    var formData = new FormData(this);

    function searchOurPrices() {
      let cityFrom = formData.get("trankompCityFrom")
      let cityTo = formData.get("trankompCityTo")

      let cityToPrice = {}

      if (cityFrom === 'Москва'){
        cityToPrice = citiesPriceData.msk.filter(function(val) {
          return val.name === cityTo;
        })[0];
      }
      if (cityFrom === 'Санкт-Петербург'){
        cityToPrice = citiesPriceData.spb.filter(function(val) {
          return val.name === cityTo;
        })[0];
      }
      if (cityToPrice?.price) {
        ourPrice = cityToPrice.price
      } else {
        let otherCityToPrice = citiesToInfo.filter(function(val) {
          return val.sklonenie_goroda === cityTo;
        })[0];
        if (cityFrom === 'Москва' && otherCityToPrice.region === 'Москва и область') ourPrice = 1800
        if (cityFrom === 'Санкт-Петербург' && otherCityToPrice.region === 'Санкт-Петербург и область') ourPrice = 1800
      }
    }

    function doParallelCalculations() {
      var request = new XMLHttpRequest();
      request.open("POST", (domain ? domain : "") + "/transport/do-parallel-calculations/");
      request.send(formData);
      searchOurPrices()
      // request.onload = function(e) {};
    }

    doParallelCalculations();

    function doCalculations(opts) {
      formData.set("trankompCompanies[]", opts.companyName);

      var request = new XMLHttpRequest();
      request.open("POST", (domain ? domain : "") + "/transport/do-calculations/");
      // request.setRequestHeader("Content-Type", "application/json");
      request.send(formData);
      request.onload = function () {
        opts.onLoad();

        if (request.status != 200) return;

        var companies = JSON.parse(request.response);

        for (var companyName in companies) {
          var company = companies[companyName];
          var url = props.localUrl ? company['localUrl'] : company['url'];
          var metrika = props.localUrl ? '' : company['metrika'];
          var companyLink = '<a onclick="' + metrika + '" class="trankomp-company-link" href="' + url + '" target="_blank">' + company['companyName'] + '</a>';

          if (!company || company.length === 0) continue;

          if (company['errorDesc'] || !company['price']) {
            opts.onError(companyLink);
            continue;
          }

          var tr = document.createElement('tr');
          var tdCompanyName = document.createElement('td');
          var tdPrice = document.createElement('td');
          var tdDeliveryDate = document.createElement('td');

          tr.appendChild(tdCompanyName);
          tr.appendChild(tdPrice);
          tr.appendChild(tdDeliveryDate);
          document.querySelector("#trankompTable").appendChild(tr);

          tdCompanyName.innerHTML = '<div style="display: flex;align-items: center;">\
          <img src="' + domain + '/img/logos-mini/' + companyName + '.png" style="width: 25px; margin-right: 8px" />\
          ' + companyLink + '\
          </div>';

          var fieldsWithPrices = [
            'price',
            'deliveryPriceForSender',
            'deliveryPriceForRecipient',
            'insurance',
            'rigidPackaging'
          ];

          for (var i = 0; i < fieldsWithPrices.length; i++) {
            var key = fieldsWithPrices[i];
            var com = company[key];
            var name = '';

            switch (key) {
              case 'deliveryPriceForSender':
                name = 'забор';
                break;
              case 'deliveryPriceForRecipient':
                name = 'доставка';
                break;
              case 'insurance':
                name = 'страховка';
                break;
              case 'rigidPackaging':
                name = 'обрешетка';
                break;
            }

            company[key] = com == 'included'
                ? '<br />' + name + ' (услуга включена в стоимость)'
                : com
                    ? (key == 'price' ? '' : "<br />+ " + name + " ") + Math.round(com) + ' руб.'
                    : 0
          }

          // tdPrice.innerHTML = company['price'] + (company['priceDesc'] ? ' (' + escapeHtml(company['priceDesc']) + ')' : '') + '\
          //   ' + (company['deliveryPriceForSender'] || '') + '\
          //   ' + (company['deliveryPriceForRecipient'] || '') + '\
          //   ' + (company['insurance'] || '') + '\
          //   ' + (company['rigidPackaging'] || '') + '\
          //   ' + (company['descUnderPrice'] ? '<br />' + company['descUnderPrice'] : '');

          tdPrice.innerHTML = ourPrice + ' руб.'

          if (company['days']) {
            var days = String(company['days']).replace(/\s+/g, '');
            var maxDays = days.split("-");
            maxDays = maxDays.length == 2 ? maxDays[1] : maxDays[0];

            if (company['localUrl'] === "/dpd/" || company['localUrl'] === "/delovye-linii/"){
              let cityFrom_t = formData.get("trankompCityFrom")
              let cityTo_t = formData.get("trankompCityTo")

              let cityToTime = {}

              if (cityFrom_t === 'Москва'){
                cityToTime = citiesTimeData.msk.filter(function(val) {
                  return val.name === cityTo_t;
                })[0];
              }
              if (cityFrom_t === 'Санкт-Петербург'){
                cityToTime = citiesTimeData.spb.filter(function(val) {
                  return val.name === cityTo_t;
                })[0];
              }
              if (cityToTime?.time) {
                days = cityToTime.time
                maxDays = cityToTime.time
              }
            }

            tdDeliveryDate.textContent = days + " " + plural(maxDays, ['день', 'дня', 'дней']);
          }
        }
      };
    }

    var loaderIndicatorStyle = colorScheme ? 'style="background: ' + colorScheme + '"' : '';
    var container = document.querySelector('#trankompResult');
    container.style.display = 'block';
    container.innerHTML = '<div class="trankomp-result">\
      <div class="trankomp-result-title">Результаты расчета</div>\
      <div class="trankomp-result-attention">К срокам добавляйте <b>1 сутки</b>, необходимые для сборки заказа и доставки до терминала ТК.</div>\
      <table id="trankompTable" style="display: table;">\
        <tr>\
          <td><b>Компании</b></td>\
          <td><b>Стоимость</b></td>\
          <td><b>Срок доставки</b></td>\
        </tr>\
      </table>\
      <div id="failed-result-container" style="margin-top: 8px;"></div>\
      <div id="__loading-indicator" style="display: block;text-align: center;padding: 5px;">\
        <div class="trankomp-spinner">\
          <div ' + loaderIndicatorStyle + ' class="trankomp-bounce1"></div>\
          <div ' + loaderIndicatorStyle + ' class="trankomp-bounce2"></div>\
          <div ' + loaderIndicatorStyle + ' class="trankomp-bounce3"></div></div>\
      </div>\
    </div>';

    var companyNames = document.querySelectorAll('[name="trankompCompanies[]"]:checked');
    companyNames = Array.from(companyNames);
    var decrement = companyNames.length;

    var loaderIndicator = document.querySelector("#__loading-indicator");

    var trankompBtn = document.querySelector('.trankomp-btn');
    trankompBtn.disabled = true;

    var failedResultContainer = document.querySelector("#failed-result-container");
    failedResultContainer.innerHTML = "";

    var failedCompanies = [];

    for (var i = 0; i < companyNames.length; i++)
      doCalculations({
        companyName: companyNames[i].value,
        onError: function (companyLink) {
          failedCompanies.push(companyLink);

          if (failedCompanies.length === companyNames.length) {
            document.querySelector("#trankompTable").style.display = "none";
            failedResultContainer.innerHTML = 'Данные не найдены, попробуйте изменить параметры.';
            return;
          }

          var word = failedCompanies.length === 1 ? 'компании' : 'компаниям';
          failedResultContainer.innerHTML = 'Данные по ' + word + ' ' + failedCompanies.join(", ") + ' не найдены, попробуйте изменить параметры.';
        },
        onLoad: function () {
          --decrement;

          if (decrement === 0) {
            loaderIndicator.style.display = "none";
            trankompBtn.disabled = false;
          }
        }
      });
  };

  searchCityByLetters({
    containerId: 'trankomp-city-from-input',
    name: "trankompCityFrom",
    value: props.cityFrom,
    domain: domain,
    required: true,
  });
  searchCityByLetters({
    containerId: 'trankomp-city-to-input',
    name: "trankompCityTo",
    value: props.cityTo,
    domain: domain,
    required: true,
    colorScheme: colorScheme,
  });


  loadCss(domain + "/css/calculator-style.css", "ID-WIOIMXAWxdP1");
  loadCss(domain + "/js/city-search-by-letters/style.css", "ID-FDVDDFV12D");
  // loadJs(
  //     domain + "/js/city-search-by-letters/main.js",
  //     "UIYJKXIUUYSD1I",
  //     function () {
  //       searchCityByLetters({
  //         containerId: 'trankomp-city-from-input',
  //         name: "trankompCityFrom",
  //         value: props.cityFrom,
  //         domain: domain,
  //         required: true,
  //       });
  //       searchCityByLetters({
  //         containerId: 'trankomp-city-to-input',
  //         name: "trankompCityTo",
  //         value: props.cityTo,
  //         domain: domain,
  //         required: true,
  //         colorScheme: colorScheme,
  //       });
  //     }
  // );
}

/**
 *
 */
//function searchCityByLetters(containerId, name, value, mode, domain, required, colorScheme) {
function searchCityByLetters(opts) {
  let trankompGetCity = function(input, opts) {
    var timeoutId = null;
    var dropDown = input.nextSibling;

    input.oninput = function () {
      if (this.value == "") {
        dropDown.style.display = "none";
        return;
      }

      if (timeoutId != null) {
        clearTimeout(timeoutId);
      } else {
        var style = opts.colorScheme ? 'style="background: '+ opts.colorScheme +'"' : '';

        dropDown.style.display = "block";
        dropDown.innerHTML = '<div class="trankomp-spinner"><div '+ style +' class="trankomp-bounce1"></div><div '+ style +' class="trankomp-bounce2"></div><div '+ style +' class="trankomp-bounce3"></div></div>';
      }

      timeoutId = setTimeout(getCities.bind(this), 500);

      function getCities() {
        timeoutId = null;

        if (this.value == "") {
          return;
        }

        // var formData = new FormData();
        // formData.append("partialCityName", this.value);
        var request = new XMLHttpRequest();
        request.open("GET", (opts.domain || '') +'/ajax/city-search-by-letters/?partialCityName='+ encodeURIComponent(this.value));
        request.setRequestHeader("Content-Type", "application/json");
        request.send();
        request.onload = function() {
          if (request.status != 200) {
            dropDown.innerHTML = '<span>Во время запроса произошла ошибка</span>';
            return;
          }

          var cities = JSON.parse(request.response);
          if (!cities) {
            dropDown.innerHTML = '<span>Не удалось получить список городов</span>';
            return;
          }

          if (!cities.length) {
            dropDown.innerHTML = '<span>Нет такого города в базе</span>';
          } else {
            dropDown.innerHTML = '';

            if (opts.name === "trankompCityTo") {
              citiesToInfo = cities
            }

            for (var i = 0; i < cities.length; i++) {
              var a = document.createElement("a");

              (function() {
                var chpuGoroda = cities[i].chpu_goroda;
                var cityName = cities[i].sklonenie_goroda;
                a.innerHTML = cityName + '<div style="color: #9d9d9d;">'+ cities[i].region +'</div>';

                if (opts.mode) {
                  a.href = (opts.mode.prefix ? '/'+ opts.mode.prefix : "") + '/' + chpuGoroda + '/' + (opts.mode.postfix ? opts.mode.postfix + '/' : "");
                } else {
                  a.href = "#";
                  a.onclick = function () {
                    input.value = cityName;
                    dropDown.style.display = "none";

                    opts.onChange && opts.onChange(chpuGoroda);

                    return false;
                  };
                }
              })();

              dropDown.appendChild(a);
            }
          }
        };
      }
    };

    document.onclick = function () {
      var dropDowns = document.querySelectorAll('.trankomp-cityDropDown');

      for(var i = 0; i < dropDowns.length; i++) {
        dropDowns[i].style.display = "none";
      }
    };
  };

  setTimeout(function () {
    document.querySelector("#cityField"+ (opts.name ? opts.name : "")).onfocus = function () {
      trankompGetCity(this, opts);
    }
  }, 250);

  var markup = '<div class="trankomp-citySearch">\
        <div class="trankomp-citySearchWrap">\
            <input\
                '+ (opts.required ? "required" : '') +'\
                type="search"\
                id="cityField'+ (opts.name ? opts.name : "") +'"\
                name="'+ opts.name +'"\
                value="'+ (opts.value || '') +'"\
                autocomplete="off"\
                placeholder="Начните вводить название города"\
                class="trankomp-input"\
            /><div class="trankomp-cityDropDown"></div>\
        </div>\
    </div>';

  document.querySelector("#"+ opts.containerId).innerHTML = markup;
}


