<template>
  <div>
    <div class="selector-attribute">
      <div class="selector" :class="{ active: this.active, disabled: this.disabled }" @click="clickListener" v-click-outside="clickOutside">
        <div class="selector-title" :class="{'red-border': validateError}">
          <input type="text"
                 @input="inputListener"
                 @change="inputListener"
                 @keypress="active = true"
                 :class="{'cursor': searchAction}"
                 :value="selectorLabel"
                 :readonly="!(search || searchAction) || !active"
                 :placeholder="selectorLabel"
          />
        </div>
        <div class="selector-content" v-if="this.filterOptions?.length !== 0 || searchStr">
          <div class="zero-result" v-if="searchStr && this.filterOptions.length === 0">Ничего не найдено</div>
          <span v-for="(option, index) in filterOptions"
            :key="index"
            @click="setValue(option)"
          >
            {{option.label || option.value || option }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SSPSelector',
  props: {
    error: Boolean,
    options: Array,
    value: String||Number||Boolean,
    search: {
      type: Boolean,
      default: false
    },
    searchAction: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      active: false,
      searchStr: '',
      validateError: this.error,
      timer: null
    };
  },
  computed: {
    selectorLabel() {
      if(this.active) return this.searchStr
      if(this.selectorValue === null) return this.placeholder
      let find = this.options?.find(option => option.value === this.selectorValue || option === this.selectorValue) || this.selectorValue
      return find?.label || find?.value || find || this.placeholder || null
    },
    selectorValue() {
      return this.value
    },
    filterOptions(){
      if(this.active && this.searchStr) return this.options.filter(option => this.searchInOption(option, this.searchStr.trim()))
      return this.options
    }
  },
  watch: {
    error(){
      this.validateError = this.error
    }
  },
  methods: {
    inputListener(event){
      this.active = true
      if (this.searchAction) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit("input", event.target.value);
          this.searchStr = event.target.value
        }, 500);

      } else {
        this.searchStr = event.target.value
      }

    },
    clickListener(){
      if (!this.disabled) this.active = !this.active
    },
    clickOutside(){
      if(this.active) {
        let newValue
        if(this.searchStr.length > 0 && this.options.length > this.filterOptions.length > 0 ) newValue = this.filterOptions.slice(0, 1)[0]
        else newValue = this.value
        this.setValue(newValue)
        this.active = false
      }
    },
    searchInOption(option, search){
      search = String(search).toLowerCase()
      if(typeof option === 'object') return option.label?.toLowerCase().includes(search) || option.value?.toLowerCase().includes(search)
      else if(typeof option === 'number') return String(option).toLowerCase().includes(search)
      else if(typeof option === 'string') return option.toLowerCase().includes(search)
    },
    setValue(v){
      this.searchStr = ''
      if(typeof v?.value !== 'undefined' && typeof v === 'object') v = v.value
      this.validateError = false
      this.$emit("setValue", v);
    }
  }
}
</script>

<style>
.selector-attribute {width: 100%; }
.selector {position: relative; min-height: 48px; width: 100%; margin: 10px 0 0; flex-grow: 1; }
.selector {position: relative; min-height: 48px; width: 100%; margin: 10px 0 0; flex-grow: 1; }
.selector.disabled .selector-title { background-color: #f5f7fa; }
.selector.disabled .selector-title:after { background: transparent; }
.selector.disabled .selector-title input { opacity: 0.5; }
.selector > div > input {width: 100%; height: 100%; padding-left: 16px; z-index: 10; cursor: pointer; background: none; border: none}
.selector > div > input:disabled {caret-color: transparent; cursor: pointer; pointer-events: auto !important;}
.selector::after {font-family: 'shin-font', serif; content: "\e800"; position: absolute; top: 50%; margin-top: -2px; right: 16px; font-size: 5px; color: #082167; line-height: 5px; z-index: 9; transition: 200ms;}
.selector.active::after {transform: rotate(180deg); }
.selector.active .selector-content  {opacity: 1; display: block; position: absolute}
.selector-title {display: flex; align-items: center; width: 100%; height: 48px; line-height: 120%; color: #11131F; font-size: 16px; cursor: pointer; position: relative;
  box-sizing: border-box; border-radius: 3px; background: white; border: 2px solid #D6D6D6; }
.selector-title.red-border {border-color: var(--orange)}
.selector-title > input.cursor {cursor: text}
.selector-content {display: none; flex-direction: column; width: 100%; background-color: #fcfcfc; transition: all 0.3s ease-out; opacity: 0; z-index: 11;
  box-shadow: 0 2px 2px rgb(0 32 51 / 4%), 0 4px 12px rgb(0 32 51 / 10%); overflow-y: auto; margin-bottom: 12px; margin-top: 5px; max-height: 200px; border-radius: 3px; border: 2px solid #D6D6D6;}
.selector-content > span {display: flex; align-items: center; width: 100%; height: 36px; font-size: 14px; padding: 0 12px; transition: all 0.2s ease-out; cursor: pointer; overflow: hidden; }
.selector-content > span:hover {background-color: #ffefef !important; }
.zero-result {padding: 12px; font-size: 14px; border-bottom: 1px solid var(--borderInput); font-weight: 500}
</style>
