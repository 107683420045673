<template>
  <router-view/>
</template>

<script>
import {actionTypes} from "@/store/modules/auth";
import {mapState} from 'vuex'

export default {
  name: 'McvApp',
  components: {

  },
  computed:{
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
  },
  async beforeMount() {
    await this.$store.dispatch(actionTypes.checkAuth)
  },
  watch:{
    isLoggedIn(){
      if(!this.isLoggedIn && !(this.$route.name === 'DeliveryCalculatorScript' || this.$route.name === 'DeliveryCalculator' || this.$route.name === 'DeliveryCalculatorPriority')) {
        this.$router.push({name: 'Login'})
      }
    }
  }
}
</script>

<style src="../public/css/main.css">

</style>


