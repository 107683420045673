import generalApi from '@/api/general'

const state = {
  tire: [],
  initData: [],
  isLoading: false,
  error: null
}

export const mutationTypes = {
  getTireStart: '[general] Get Tire Start',
  getTireSuccess: '[general] Get Tire Success',
  getTireFailure: '[general] Get Tire Failure',

  getInitDataStart: '[general] Get Init Data Start',
  getInitDataSuccess: '[general] Get Init Data Success',
  getInitDataFailure: '[general] Get Init Data Failure',
}

export const actionTypes = {
  getTire: '[general] getTire',
  getInitData: '[general] getInitData',
}

const mutations = {
  [mutationTypes.getTireStart](state){
    state.tire = []
    state.isLoading = true
  },
  [mutationTypes.getTireSuccess](state, payload){
    state.tire = payload.tire
    delete state.tire.analogs
    state.isLoading = false
  },
  [mutationTypes.getTireFailure](state){
    state.tire = []
    state.isLoading = false
  },
  [mutationTypes.getInitDataStart](state){
    state.initData = []
    state.isLoading = true
  },
  [mutationTypes.getInitDataSuccess](state, payload){
    state.initData = payload
    state.isLoading = false
  },
  [mutationTypes.getInitDataFailure](state){
    state.initData = []
    state.isLoading = false
  },
}

const actions = {
  [actionTypes.getTire](context, params) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getTireStart)
      generalApi.getTire(params)
          .then(response => {
            context.commit(mutationTypes.getTireSuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.getTireFailure)
          })
    })
  },
  [actionTypes.getInitData](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getInitDataStart)
      generalApi.getInitData()
          .then(response => {
            context.commit(mutationTypes.getInitDataSuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.getInitDataFailure)
          })
    })
  },
}

export default {
  state,
  mutations,
  actions
}
