import {mainAxios as axios} from "@/api/axios"

const config = {
    jsonrpc: "2.0",
    id: 1,
}

const checkAuth = async () => {
    return await axios.post('/', {...config, method: "w_check_auth"}).then(response => response.data.result)
}

const login =  Credentials => {
    return axios.post('/', {...config, method: "w_login", params: {...Credentials}}).then(response => response.data.result)
}

const logout =  () => {
    return axios.post('/', {...config, method: "w_logout"}).then(response => response.data.result)
}

export default {
    checkAuth,
    login,
    logout
}