import calcApi from '@/api/calculator'
//import {setItem} from '@/helpers/persistanceStorage'

const state = {
  tireModels: [],
  calcFormData: [],
  isLoading: false,
  buyoutCost: 0,
  error: null
}

export const mutationTypes = {
  getCalcFormDataStart: '[calc] Get Calc Form Data Start',
  getCalcFormDataSuccess: '[calc] Get Calc Form Data Success',
  getCalcFormDataFailure: '[calc] Get Calc Form Data Failure',

  getTireModelsStart: '[calc] Get Tire Models Start',
  getTireModelsSuccess: '[calc] Get Tire Models Success',
  getTireModelsFailure: '[calc] Get Tire Models Failure',

  getBuyoutCostStart: '[calc] Get Buyout Cost Start',
  getBuyoutCostSuccess: '[calc] Get Buyout Cost Success',
  getBuyoutCostFailure: '[calc] Get Buyout Cost Failure',
}

export const actionTypes = {
  getCalcFormData: '[calc] getCalcFormData',
  getTireModels: '[calc] getTireModels',
  getBuyoutCost: '[calc] getBuyoutCost'
}

const mutations = {
  [mutationTypes.getCalcFormDataStart](state){
    state.calcFormData = []
    state.isLoading = true
  },
  [mutationTypes.getCalcFormDataSuccess](state, payload){
    state.calcFormData = payload
    state.isLoading = false
  },
  [mutationTypes.getCalcFormDataFailure](state){
    state.calcFormData = []
    state.isLoading = false
  },

  [mutationTypes.getTireModelsStart](state){
    state.isLoading = true
  },
  [mutationTypes.getTireModelsSuccess](state, payload){
    state.isLoading = false
    state.tireModels = payload
  },
  [mutationTypes.getTireModelsFailure](state){
    state.tireModels = []
    state.isLoading = false
  },

  [mutationTypes.getBuyoutCostStart](state){
    state.isLoading = true
    state.error = null
  },
  [mutationTypes.getBuyoutCostSuccess](state, payload){
    state.isLoading = false
    state.error = null
    state.buyoutCost = payload.cost
  },
  [mutationTypes.getBuyoutCostFailure](state, payload){
    state.isLoading = false
    state.buyoutCost = 0
    state.error = {...payload}
  },
}

const actions = {
  [actionTypes.getCalcFormData](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getCalcFormDataStart)
      calcApi.getFormData()
          .then(response => {
            context.commit(mutationTypes.getCalcFormDataSuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.getCalcFormDataFailure)
          })
    })
  },

  [actionTypes.getTireModels](context, brand) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getTireModelsStart)
      calcApi.getTireModels(brand)
          .then(response => {
            context.commit(mutationTypes.getTireModelsSuccess, response)
            resolve(response)
          })
          .catch(response => {
            context.commit(mutationTypes.getTireModelsFailure, response)
          })
    })
  },

  [actionTypes.getBuyoutCost](context, params) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getBuyoutCostStart)
      calcApi.getBuyoutCost(params)
          .then(response => {
            if(response.error_type != null || response.error_msg != null) throw response;
            context.commit(mutationTypes.getBuyoutCostSuccess, response)
            resolve(response)
          })
          .catch(response => {
            context.commit(mutationTypes.getBuyoutCostFailure, response)
          })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
