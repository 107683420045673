import authApi from '@/api/auth'

const state = {
  isSubmitting: false,
  isLoading: false,
  validationErrors: null,
  isLoggedIn: null
}

export const mutationTypes = {
  checkAuthStart: '[auth] Check Auth Start',
  checkAuthSuccess: '[auth] Check Auth Success',
  checkAuthFailure: '[auth] Check Auth Failure',

  loginStart: '[auth] Login Start',
  loginSuccess: '[auth] Login Success',
  loginFailure: '[auth] Login Failure',

  logout: '[auth] Logout',
}

export const actionTypes = {
  checkAuth: '[auth] checkAuth',
  login: '[auth] login',
  logout: '[auth] logout'
}

export const getterTypes = {
  isLoggedIn: '[auth] isLoggedIn',
}

const getters = {
  [getterTypes.isLoggedIn]: state => {
    return Boolean(state.isLoggedIn)
  }
}

const mutations = {
  [mutationTypes.checkAuthStart](state){
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.checkAuthSuccess](state, payload){
    state.isSubmitting = false
    state.isLoggedIn = payload
  },
  [mutationTypes.checkAuthFailure](state){
    state.isSubmitting = false
  },

  [mutationTypes.loginStart](state){
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.loginSuccess](state){
    state.isSubmitting = false
    state.isLoggedIn = true
  },
  [mutationTypes.loginFailure](state){
    state.isSubmitting = false
  },

  [mutationTypes.logout](state){
    state.isLoggedIn = false
  }
}

const actions = {
  [actionTypes.checkAuth](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.checkAuthStart)
      authApi.checkAuth()
          .then(response => {
            context.commit(mutationTypes.checkAuthSuccess, response.is_auth)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.checkAuthFailure)
          })
    })
  },

  [actionTypes.login](context, credentials) {
    return new Promise(resolve => {
      context.commit(mutationTypes.loginStart)
      authApi.login(credentials)
        .then(response => {
          if(response.status === 'ok') context.commit(mutationTypes.loginSuccess)
          if(response.status === 'fail') throw response;
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.loginFailure)
        })
    })
  },

  [actionTypes.logout](context) {
    return new Promise(resolve => {
      authApi.logout()
      context.commit(mutationTypes.logout)
      resolve()
    })
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
