<template>
  <div class="calculator-page main-wrapper">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div class="search_code">
          <SSPLoader v-if="isLoading" />
          <div id="transportnye-kompanii"></div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import {mapState} from "vuex";
import {calculatorBuilder} from "@/assets/js/tk";

export default {
  name: 'SSPDeliveryCalculatorScript',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader,
  },
  data(){
    return {
    }
  },
  mounted() {
    calculatorBuilder({
      companies: [
        ['pec', 'ПЭК'],
        ['gtd', 'КИТ'],
        ['dellin', 'Деловые линии'],
        ['dpd', 'DPD'],
      ],
      title: 'Калькулятор сроков доставки',
      cityFrom: '',
      cargoPickup: true,
      cargoDelivery: true,
      insurance: false,
      rigidPackaging: false,
      colorScheme: '#FA4735',
      buttonTextColor: 'white',
    });
  },
  computed: {
    ...mapState({
      isLoading: state => state.general.isLoading,
      error: state => state.general.error,
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
  },
  methods: {
  }
}
</script>
