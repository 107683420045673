<template>
  <div class="calculator-page">
    <SSPHeader/>
    <div class="container">
      <h1>Выкупной калькулятор, шины</h1>
    </div>
    <main class="container">
      <section class="content">
        <div class="calculator" v-if="isLoggedIn">
          <SSPLoader v-if="isLoading" />
          <h2>Расчет выкупной цены</h2>
          <div class="fieldset">
            <span>Выберите сезонность и год выпуска шин:</span>
            <div>
              <SSPSelector :placeholder="'Сезонность'" :value="sendData.season" @setValue="sendData.season = $event" :options="standardFormData.season" :error="required.season"/>
              <SSPSelector :placeholder="'Тип зимних шин'" :value="sendData.is_studded" @setValue="sendData.is_studded = $event" :options="standardFormData.studded" v-if="sendData.season === 'w'" />
              <SSPSelector :placeholder="'Год выпуска'" :value="sendData.year" @setValue="sendData.year = $event" :options="standardFormData.years" :search="true" :error="required.year"/>
            </div>
          </div><!--fieldset-->
          <div class="fieldset">
            <span>Усиленные шины (категория С):</span>
            <div>
              <SSPSelector :value="sendData.is_reinforced" @setValue="sendData.is_reinforced = $event" :options="standardFormData.is_reinforced" :error="required.is_reinforced" />
            </div>
          </div><!--fieldset-->
          <div class="fieldset">
            <span>Имеют ли шины технологию RunFlat?</span>
            <div>
              <SSPSelector :value="sendData.is_runflat" @setValue="sendData.is_runflat = $event" :options="standardFormData.is_runflat" :error="required.is_runflat" />
            </div>
          </div><!--fieldset-->
          <div class="fieldset">
            <span>Выберите бренд товара:</span>
            <div>
              <SSPSelector :placeholder="'Бренд'" :value="sendData.brand" @setValue="sendData.brand = $event" :options="calcFormData.brands" :search="true" :error="required.brand"/>
            </div>
          </div><!--fieldset-->
          <div class="fieldset">
            <span>Выберите модель товара:</span>
            <div>
              <SSPSelector :placeholder="'Модель'" :value="sendData.tire_model" @setValue="sendData.tire_model = $event" :options="tireModels" :search="true" :error="required.tire_model"/>
            </div>
          </div><!--fieldset-->
          <div class="fieldset">
            <span>Выберите размер:</span>
            <div class="row-triple-blocks">
              <SSPSelector :placeholder="(flags.difference_rear)? 'Ширина (Передние)' : 'Ширина'" :value="sendData.width_front" @setValue="sendData.width_front = $event" :options="calcFormData.widths" :search="true" :error="required.width_front"/>
              <SSPSelector :placeholder="(flags.difference_rear)? 'Профиль (Передние)' : 'Профиль'" :value="sendData.aspect_ratio_front" @setValue="sendData.aspect_ratio_front = $event" :options="calcFormData.aspect_ratios" :search="true" :error="required.aspect_ratios"/>
              <SSPSelector :placeholder="'Диаметр'" :value="sendData.diameter" @setValue="sendData.diameter = $event" :options="calcFormData.diameters" :search="true" :error="required.diameter"/>
            </div>
            <transition>
              <div v-if="flags.difference_rear" class="row-triple-blocks">
                <SSPSelector :placeholder="'Ширина (Задние)'" :value="sendData.width_rear" @setValue="sendData.width_rear = $event" :options="calcFormData.widths" :search="true" />
                <SSPSelector :placeholder="'Профиль (Задние)'" :value="sendData.aspect_ratio_rear" @setValue="sendData.aspect_ratio_rear = $event" :options="calcFormData.aspect_ratios" :search="true" />
              </div>
            </transition>
            <button class="btn btn-expand" @click="flags.difference_rear = ! flags.difference_rear">
              <i v-if="!flags.difference_rear" class="icon icon-plus"></i>
              <i v-if="flags.difference_rear" class="icon icon-minus"></i>
              <span>&nbsp;{{ flags.difference_rear ? 'Скрыть' : 'Добавить'}}&nbsp;отличающийся задний размер (Разноширокие)</span>
            </button>
          </div><!--fieldset-->
          <div class="fieldset">
            <span>Внесите износ <span v-if="flags.difference_rear">ПЕРЕДНИХ шин</span> в процентах:</span>
            <div>
              <input type="number" v-model="sendData.wear_front" @input="required.wear_front = false" :class="{'red-border': required.wear_front}">
            </div>
          </div><!--fieldset-->
          <div class="fieldset" v-if="flags.difference_rear">
            <span>Внесите износ <span>ЗАДНИХ шин</span> в процентах:</span>
            <div>
              <input type="number" v-model="sendData.wear_rear">
            </div>
          </div><!--fieldset-->
          <div class="fieldset">
            <span>Выберите количество шин:</span>
            <div>
              <input type="number" :min="(flags.difference_rear) ? 2 : 1 " v-model="sendData.count" @input="required.count = false" :class="{'red-border': required.count}">
            </div>
          </div><!--fieldset-->
          <div class="fieldset-group">
            <button class="btn btn-expand" @click="flags.defects = ! flags.defects" :class="{'rotate-icon': flags.defects}">
              <i class="icon icon-input-arrow"></i>&nbsp;
              <span v-if="!flags.defects">Раскрыть</span>
              <span v-else>Скрыть</span>
              &nbsp;пареметры дефектов
            </button>
            <div class="defects" v-if="flags.defects">
              <div class="fieldset">
                <span>Выберите степень неравномерного износа:</span>
                <div>
                  <SSPSelector :placeholder="'Степень неравномерного износа'" :value="sendData.defect_wear_rate" @setValue="sendData.defect_wear_rate = $event" :options="standardFormData.defect_wear_rate" />
                </div>
              </div><!--fieldset-->
              <div class="fieldset" v-if="sendData.is_studded && sendData.season === 'w'">
                <span>Выберите степень недостатка шипов:</span>
                <div>
                  <SSPSelector :placeholder="'Степень недостатка шипов'" :value="sendData.defect_studded_rate" @setValue="sendData.defect_studded_rate = $event" :options="standardFormData.defect_studded_rate" />
                </div>
              </div><!--fieldset-->
              <div class="fieldset">
                <span>Выберите количество шин со следами езды на спущенном колесе:</span>
                <div>
                  <input type="number" min="0" :max="sendData.count" v-model="sendData.flat_tire_count">
                </div>
              </div><!--fieldset-->
              <div class="fieldset">
                <span>Выберите количество шин с трещинами:</span>
                <div>
                  <input type="number" min="0" :max="sendData.count" v-model="sendData.cracked_tire_count">
                </div>
              </div><!--fieldset-->
              <div class="fieldset">
                <span>Выберите степень &laquo;пощипанности&raquo; шин:</span>
                <div>
                  <SSPSelector :placeholder="'Степень &laquo;пощипанности&raquo; шин'" :value="sendData.defect_flaking_rate" @setValue="sendData.defect_flaking_rate = $event" :options="standardFormData.defect_flaking_rate" />
                </div>
              </div><!--fieldset-->
            </div><!--fieldset-->
          </div><!--defects-->
          <div class="fieldset">
            <span>Канал выкупа: </span>
            <div>
              <SSPSelector :placeholder="'Канал выкупа (по-умолчанию: Частные лица)'" :value="sendData.bc_slug" @setValue="sendData.bc_slug = $event" :options="preparedBuyingChannels" />
            </div>
          </div><!--fieldset-->
          <div class="actions">
            <div>
              <button class="btn btn-submit" @click="calculate">Рассчитать стоимость</button>
            </div>
            <div class="result">
              <div class="price" v-if="buyoutCost > 0">Выкупная цена: <b>{{buyoutCost.toLocaleString('ru-Ru')}}</b> &#8381;</div>
              <div class="error" v-if="error">{{error.error_msg}}</div>
            </div>
          </div><!--actions-->
        </div>
      </section>
      <section class="sidebar">
        <button @click="copyLink" class="btn btn-submit">Копировать ссылку</button>
        <div class="annotation">Не выкупаем с боковыми<br>латками и/или грыжами</div>
        <div class="annotation">Не выкупаем с <br>износом более<br>60%</div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPSelector from "@/components/Selector";
import SSPLoader from "@/components/Loader";
import {actionTypes} from "@/store/modules/calculator";
import {mapState} from "vuex";

import {inJson, rangeYears} from '@/helpers/utils'

export default {
  name: 'SSPCalculator',
  components: {
    SSPHeader,
    SSPFooter,
    SSPSelector,
    SSPLoader,
  },
  data(){
    return {
      flags: {
        difference_rear: false,
        defects: false,
      },
      required: {
        season: false,
        year: false,
        is_reinforced: false,
        is_runflat: false,
        brand: false,
        tire_model: false,
        width_front: false,
        aspect_ratio_front: false,
        diameter: false,
        wear_front: false,
        count: false
      },
      sendData: {
        season: null,
        is_studded: false,
        year: null,
        is_reinforced: false,
        is_runflat: false,
        brand: null,
        tire_model: null,
        width_front: null,
        aspect_ratio_front: null,
        diameter: null,
        width_rear: null,
        aspect_ratio_rear: null,
        wear_front: null,
        wear_rear: null,
        count: 4,
        defect_wear_rate: null,
        defect_studded_rate: null,
        flat_tire_count: null,
        cracked_tire_count: null,
        defect_flaking_rate: null,
        bc_slug: 'individuals'
      },
      standardFormData: {
        is_reinforced: [
          {label: 'Да', value: true},
          {label: 'Нет', value: false}
        ],
        is_runflat: [
          {label: 'Да', value: true},
          {label: 'Нет', value: false}
        ],
        years: rangeYears(2000, new Date().getFullYear()).reverse(),
        season: [
          {label: 'Зима', value: 'w'},
          {label: 'Лето', value: 's'}
        ],
        studded: [
          {label: 'С шипами', value: true},
          {label: 'Без шипов', value: false}
        ],
        defect_wear_rate: [
          {label: 'Не установлено', value: 0},
          {label: 'Слабая', value: 1},
          {label: 'Средняя', value: 2},
          {label: 'Сильная', value: 3}
        ],
        defect_studded_rate: [
          {label: 'Не установлено', value: 0},
          {label: 'Слабая', value: 1},
          {label: 'Средняя', value: 2},
          {label: 'Сильная', value: 3}
        ],
        defect_flaking_rate: [
          {label: 'Не установлено', value: 0},
          {label: 'Слабая', value: 1},
          {label: 'Средняя', value: 2},
          {label: 'Сильная', value: 3}
        ]
      }
    }
  },

  computed: {
    ...mapState({
      calcFormData: state => state.calculator.calcFormData,
      tireModels: state => state.calculator.tireModels,
      isLoading: state => state.calculator.isLoading,
      buyoutCost: state => state.calculator.buyoutCost,
      error: state => state.calculator.error,
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
    preparedBuyingChannels(){
      return this.calcFormData.buying_channels?.map(function (obj) {
        obj['label'] = obj['name']
        obj['value'] = obj['slug']
        delete obj['name']
        delete obj['slug']
        return obj
      });
    }
  },
  async beforeMount() {
    let query = this.$route.query
    Object.keys(query).map(key => {query[key] = inJson(query[key]) });
    this.sendData = Object.assign(this.sendData, query)
    await this.$store.dispatch(actionTypes.getCalcFormData)

    if(this.sendData.width_rear !== null) this.flags.difference_rear = true

    if( 'defect_wear_rate' in query ||
        'defect_studded_rate' in query ||
        'flat_tire_count' in query ||
        'cracked_tire_count' in query ||
        'defect_flaking_rate' in query ) this.flags.defects = true

    let bc_slugs_isset = this.calcFormData.buying_channels.find((obj) => obj.value === query.bc_slug)
    if( 'bc_slug' in query && (typeof bc_slugs_isset === "undefined" || bc_slugs_isset === null)) this.sendData.bc_slug = null

    if(Object.keys(this.$route.query).length !== 0) await this.$store.dispatch(actionTypes.getBuyoutCost, this.sendData)
  },
  watch: {
    'sendData.brand'() {
      this.sendData.tire_model = null
      this.$store.dispatch(actionTypes.getTireModels, this.sendData.brand)
    }
  },
  methods: {
    validate(data){
      let count = 0
      Object.keys(this.required).forEach(key => {
        if(!(key in data)) {
          this.required[key] = true
          count++
        }
      })
      if(count === 0) return data
      else return false
    },
    calculate() {
      let data = this.prepareData()
      if(!data) return false;
      this.$router.push( { query: data })
      this.$store.dispatch(actionTypes.getBuyoutCost, data)
    },
    prepareData(){
      let data = JSON.parse(JSON.stringify(this.sendData))
      if(!this.flags.defects){
        data.defect_wear_rate = null
        data.defect_studded_rate = null
        data.flat_tire_count = null
        data.cracked_tire_count = null
        data.defect_flaking_rate = null
      }
      if(!this.flags.difference_rear){
        data.width_rear = null
        data.aspect_ratio_rear = null
        data.wear_rear = null
      }
      if(this.sendData.season === 's'){
        data.is_studded = false
        data.defect_studded_rate = null
      }
      data = Object.entries(data).reduce((a,[k,v]) => (v == null ? a : {...a, [k]:v}), {})
      return this.validate(data)
    },
    copyLink(){
      let url = document.location.href
      navigator.clipboard.writeText(url).then(function() {
        console.log('Copied!');
      }, function() {
        console.log('Copy error')
      });
    }
  }
}
</script>
