import dadataApi from '@/api/dadata'

const state = {
  cities: [],
  selectCity: null,
  isLoading: false,
  error: null
}

export const mutationTypes = {
  getCitiesStart: '[dadata] Get Cities Start',
  getCitiesSuccess: '[dadata] Get Cities Success',
  getCitiesFailure: '[dadata] Get Cities Failure',
}

export const actionTypes = {
  getCities: '[dadata] getCities',
}

const mutations = {
  [mutationTypes.getCitiesStart](state){
    state.cities = []
    state.isLoading = true
  },
  [mutationTypes.getCitiesSuccess](state, payload){
    state.cities = payload
    state.isLoading = false
  },
  [mutationTypes.getCitiesFailure](state){
    state.cities = []
    state.isLoading = false
  }
}

const actions = {
  [actionTypes.getCities](context, query) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getCitiesStart)
      dadataApi.getCities(query)
          .then(response => {
            context.commit(mutationTypes.getCitiesSuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.getCitiesFailure)
          })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
