<template>
  <header>
    <div class="container main_header">
      <a href="/" class="logo"><img src="../assets/img/logo.svg" alt=""></a>
      <ol class="header-links">
        <li><a href="tel:+74954077657"><i class="icon icon-phone"></i><span>+7 (495) 407-76-57</span></a></li>
        <li><a href="/" @click="logOut()"><i class="icon icon-profile"></i><span>shinsale_varshavka</span></a></li>
      </ol>
    </div>
  </header>
  <nav class="navigation">
    <div class="container">
      <router-link class="nav-link" :to="{name: 'Calculator'}" v-if="isLoggedIn" active-class="active">
        Калькулятор
      </router-link>
      <router-link class="nav-link" :to="{name: 'SearchCode'}" v-if="isLoggedIn"  active-class="active">
        Поиск по артикулу
      </router-link>
      <router-link class="nav-link" :to="{name: 'DeliveryCalculatorScript'}" active-class="active">
        Калькулятор сроков ТК
      </router-link>
      <router-link class="nav-link" :to="{name: 'DeliveryCalculator'}" active-class="active">
        Калькулятор стоимости и сроков ТК (НОВЫЙ)
      </router-link>
    </div>
  </nav>
</template>

<script>
import {actionTypes} from "@/store/modules/auth";
import {mapState} from "vuex";

export default {
  name: 'SSPHeader',
  props: {
    msg: String
  },
  computed:{
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
  },
  methods: {
    async logOut (){
      await this.$store.dispatch(actionTypes.logout)
    }
  }
}
</script>
