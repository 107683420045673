import { createRouter, createWebHistory } from 'vue-router'
import SSPCalculator from '@/views/Calculator'
import SSPLogin from '@/views/Login.vue'
import SSPSearchCode from '@/views/SearchCode'
import SSPDeliveryCalculatorScript from '@/views/DeliveryCalculatorScript'
import SSPDeliveryCalculatorPriority from '@/views/DeliveryCalculatorPriority'
import SSPDeliveryCalculator from '@/views/DeliveryCalculator'

const routes = [
  {
    path: '/',
    name: 'Calculator',
    component: SSPCalculator,
  },
  {
    path: '/search_code',
    name: 'SearchCode',
    component: SSPSearchCode,
  },
  {
    path: '/external_delivery_calculator',
    name: 'DeliveryCalculatorScript',
    component: SSPDeliveryCalculatorScript
  },
  {
    path: '/delivery_calculator',
    name: 'DeliveryCalculator',
    component: SSPDeliveryCalculator
  },
  {
    path: '/delivery_calculator/set_priority',
    name: 'DeliveryCalculatorPriority',
    component: SSPDeliveryCalculatorPriority
  },
  {
    path: '/login',
    name: 'Login',
    component: SSPLogin
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
