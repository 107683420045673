import axios from "axios"

const mainAxios = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true
});

const generalAxios = axios.create({
    baseURL: process.env.VUE_APP_GENERAL_API
});

const dadataAxios = axios.create({
    baseURL: process.env.VUE_APP_DADATA_API,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + process.env.VUE_APP_DADATA_TOKEN,
    }
});

const deliveryAxios = axios.create({
    baseURL: process.env.VUE_APP_DELIVERY_API
});

export {
    mainAxios,
    generalAxios,
    dadataAxios,
    deliveryAxios
};
